import React from "react";
import PropTypes from "prop-types";

const Notifications = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={fill}
            d="M21,13a2,2,0,0,1-2-2V7A7,7,0,0,0,5,7v4a2,2,0,0,1-2,2,3,3,0,0,0-3,3,3,3,0,0,0,3,3H9.19a3,3,0,0,0,5.62,0H21a3,3,0,0,0,0-6Zm0,4H3a1,1,0,0,1-1-1,1,1,0,0,1,.31-.71A.93.93,0,0,1,3,15a4,4,0,0,0,4-4V7A5,5,0,0,1,17,7v4a4,4,0,0,0,4,4,1,1,0,0,1,0,2Z"
          />
        </g>
      </g>
    </svg>
  );
};

Notifications.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Notifications.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Notifications);
