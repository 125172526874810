// Libraries
import React, { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { Button, Popup } from "semantic-ui-react";
import Notifications from "../../../../icon/Notifications";

// constants
import { renderInsightHeaderIcon } from "../../.././../helper";

const Header = (props) => {
  const [showdropdown, setShowDropdown] = useState(false);
  const { menu = [], onClickTab, user = {}, setUserInfo } = props;
  const { email = "", name = "" } = user;

  const onCLickLogOut = () => {
    setUserInfo();
    return <Redirect to="/login" />;
  };

  const renderTabBar = () => {
    return (
      <StyledUnOrderList>
        {menu.map((item) => {
          const { name = "", key = "", active } = item;
          const activeColorCode = active ? "#999" : "#000";
          return (
            <StyledUnOrderListItem onClick={onClickTab(key)} key={name} active={active}>
              {renderInsightHeaderIcon(key, activeColorCode)}
              <span> {name}</span>
              {active && <ActiveItem />}
            </StyledUnOrderListItem>
          );
        })}
      </StyledUnOrderList>
    );
  };

  const onclickDropdown = () => {
    setShowDropdown(!showdropdown);
  };

  const renderAddDropDownTrigger = () => {
    return (
      <span className="dropdownTriggerHeader">
        <Button content={name} icon="angle down" labelPosition="right" />
      </span>
    );
  };

  const renderPopupContent = () => {
    return (
      <div className="popcontent">
        <div className="pointer" onClick={onCLickLogOut}>
          Logout
        </div>
      </div>
    );
  };

  const renderPopup = () => {
    return (
      <Popup
        hoverable
        open={showdropdown}
        onClose={onclickDropdown}
        on="click"
        position="bottom right"
        onOpen={onclickDropdown}
        content={renderPopupContent()}
        trigger={renderAddDropDownTrigger()}
      />
    );
  };

  const renderUserInfo = () => {
    return (
      <div>
        <StyledUnOrderList>
          <StyledUnOrderListItemRight>
            <Notifications fill={`#999`} size={24} />
            <span className="ml5">Notifications</span>
          </StyledUnOrderListItemRight>
          <StyledUnOrderListItemRight>{renderPopup()}</StyledUnOrderListItemRight>
        </StyledUnOrderList>
      </div>
    );
  };

  return (
    <StyledHeader>
      <StyledHeaderInner>
        {renderTabBar()}
        {renderUserInfo()}
      </StyledHeaderInner>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  background: #fefdfd;
  height: 96px;
  width: 100%;
  border-bottom: 1px solid #efe9ed;
  display: flex;
  align-items: center;
`;
const StyledHeaderInner = styled.div`
  background: #fefdfd;
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding-left: 100px;
  padding-right: 40px;
`;
const ActiveItem = styled.span`
  border-bottom: 4px solid #ffc82d;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const StyledUnOrderList = styled.ul`
  padding: 0;
  margin: 0;
  padding-left: 40px;
  display: flex;
  list-style: none;
`;

const StyledUnOrderListItem = styled.li`
  display: flex;
  align-items: center;
  margin-right: 4rem;
  cursor: pointer;
  padding: 30px 0 30px 0;
  position: relative;
  padding: ${(props) => (props.active ? "32px 0 30px 0" : "30px 0 30px 0")};
  span {
    margin-left: 5px;
    color: ${(props) => (props.active ? "#999" : "#000")}
`;
const StyledUnOrderListItemRight = styled.li`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
  padding: 30px 0 30px 0;
`;


export default Header;
