// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import get from "lodash/get";
import { addDays } from 'date-fns';
import DatePicker from "react-datepicker";
import { Dropdown } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import "./style/index.css";

import Calendar from "../../../../icon/Calendar";
import Close from "../../../../icon/Close";
import {
  END_DATE,
  START_DATE,
  AGE_GROUP,
  GENDER,
  REGION,
  SECTOR,
  BROWSE,
  CUSTOM,
  SEARCH,
  KEY_9,
} from "../../../../constants";
import { filterYears, filterGender, filterRegion, filterTab, filterDate } from "../../../../constants";

import { dateTimeHelper } from "../../../../helper";

import CompanyList from "../../companyList";
import { isEmpty } from "lodash";

import AutoSuggestWrapper from "../../AutoSuggestWrapper";

const InsightsBrowseAndSearch = (props) => {
  const {
    formState,
    updateFormState,
    sectorList,
    handlerCompany,
    companies,
    updateCompanies,
    setFormState,
    handlerRunReports,
    updateFormStateWithKey,
    henderDropdownValue,
    isLoadingCompany,
    isLoadingRunReport,
    setCompanyList,
    setEmptyInsightGraph,
    setFilterTab,
    setSearchInsightFilters,
    errorState,
    addFavouriteCompanyName,
    setCompanyColorMapping,
    emptyDetailPage,
    preFillTab,
    setPreFillTab,
  } = props;

  const [tab, setTab] = useState([]);
  const [selecedTab, setSelecedTab] = useState("");
  const [dateTimeStatus, setDateTimeStatus] = useState(false);
  const { companies: selectedCompanies = [] } = formState;
  const { date: errorDate = "", companies: errorCompanies = "" } = errorState;
  const currentItem = new Date();

  useEffect(() => {
    setTab(filterTab);
    const getDate = dateTimeHelper(6);
    const { stateDate, endDay } = getDate;
    if (preFillTab) {
      setSelecedTab(preFillTab);
      setFilterTab(preFillTab);
      emptyDetailPage();
      setPreFillTab("");
      return;
    }

    setSelecedTab(BROWSE);
    setFilterTab(BROWSE);
    const newForm = {
      ...formState,
      stateDate: stateDate,
      endDate: endDay,
      dateValue: 6,
      sector: [],
      gender: [],
      age: [],
      tier: [],
      companies: [],
    };
    setCompanyList([]);
    setFormState(newForm);
    setCompanyColorMapping([]);
    emptyDetailPage();
  }, []);

  const handlerTab = (value = "") => {
    setSelecedTab(value);
    // const getDate = dateTimeHelper(6);
    // const { stateDate, endDay } = getDate;
    const newForm = {
      ...formState,
    //  dateValue: 6,
      // stateDate: stateDate,
      // endDate: endDay,
      //sector: [],
      //gender: [],
      //age: [],
      //tier: [],
      companies: [],
    };
  //  setEmptyInsightGraph();
    setFormState(newForm);
    setFilterTab(value);
   // setSearchInsightFilters();
    setCompanyList([]);
    setCompanyColorMapping([]);
   // emptyDetailPage();
  };

  const handlerDateTime = (value) => {
    if (value === CUSTOM) {
      setDateTimeStatus(true);
      const newForm = {
        ...formState,
        dateValue: value,
      };
      setFormState(newForm);
    } else {
      const getDate = dateTimeHelper(value);
      console.log(getDate)
      const { stateDate, endDay } = getDate;
      const newForm = {
        ...formState,
        dateValue: value,
        [END_DATE]: endDay,
        [START_DATE]: stateDate,
      };
      setFormState(newForm);
      setDateTimeStatus(false);
    }
  };

  const ddSectorList = sectorList.map((item) => {
    const { display_name = "", select_name = "" } = item;
    return { text: display_name, value: select_name, key: select_name };
  });

  const renderTab = () => {
    return (
      <StyledUnOrderList className="filterTab">
        {tab.map((item) => {
          const { name = "", key = "" } = item;
          const active = selecedTab === key;
          return (
            <StyledUnOrderListItem
              onClick={() => {
                handlerTab(key);
              }}
              active={active}
              key={key}
            >
              {name}
            </StyledUnOrderListItem>
          );
        })}
      </StyledUnOrderList>
    );
  };

  const renderFilterDate = () => {
    return (
      <div>
        <div className="mt40 dateTime">
          <label className="lable">
            Date Range <span>*</span>
          </label>
          <Dropdown
            placeholder="DATE"
            fluid
            clearable
            className="w280 mb10"
            search
            selection
            value={formState.dateValue}
            onChange={(e, data) => handlerDateTime(data.value)}
            options={filterDate}
          />
          {dateTimeStatus && (
            <DateTime>
              <div className="positionRelative">
                <DatePicker
                  selected={formState.stateDate}
                  className="w120 iconDate"
                  placeholderText="Start Date"
                  icon={<Calendar size={20} />}
                  maxDate={currentItem}
                  onChange={(data) => updateFormStateWithKey(data, START_DATE)}
                />
              </div>
              <div className="positionRelative">
                <DatePicker
                  className="w120 ml10 iconDate"
                  placeholderText="End Date"
                  selected={formState.endDate}
                  maxDate={currentItem}
                  onChange={(data) => updateFormStateWithKey(data, END_DATE)}
                />
              </div>
            </DateTime>
          )}
          {errorDate && <div className="error">{errorDate}</div>}
        </div>
      </div>
    );
  };

  const handleSuggestionClick = (value = "") => {
    console.log(value)
    const { company = "" } = value;
    updateCompanies(value);
  };

  const renderFilter = () => {
    const { age = [], gender = [], tier = [], sector = [] } = formState;
    return (
      <div className="mT25 filter">
        <label className="lable">Filter by</label>
        <div>
          <Dropdown
            placeholder="AGE GROUP"
            fluid
            clearable
            search
            selection
            className="w280"
            value={age}
            options={filterYears}
            multiple
            onChange={(e, data) => updateFormStateWithKey(data.value, AGE_GROUP)}
          />
        </div>
        <div className="mT15">
          <Dropdown
            placeholder="GENDER"
            fluid
            clearable
            search
            className="w280"
            selection
            value={gender}
            options={filterGender}
            onChange={(e, data) => updateFormStateWithKey(data.value, GENDER)}
            multiple
          />
        </div>
        <div className="mT15">
          <Dropdown
            placeholder="REGION"
            fluid
            clearable
            className="w280"
            search
            selection
            multiple
            value={tier}
            options={filterRegion}
            onChange={(e, data) => updateFormStateWithKey(data.value, REGION)}
          />
        </div>
        {selecedTab === BROWSE && (
          <div className="mT15">
            <Dropdown
              placeholder="SECTOR*"
              fluid
              clearable
              className="w280"
              search
              options={ddSectorList}
              selection
              value={get(sector, "0", "")}
              onChange={(e, data) => henderDropdownValue(data.value, SECTOR)}
            />
          </div>
        )}
        {selecedTab === SEARCH && (
          <div className="mT25">
            <p className="title">Keyword, app name or company</p>
            <AutoSuggestWrapper
              autoSuggestClasses={"w100 mb20 searchBox"}
              donotAllowOnlyNumber
              onSelectSuggestion={handleSuggestionClick}
            />
            {!isEmpty(selectedCompanies) && (
              <div className="mt20 mb20">
                <div className="smallTitle">Selected companies/ Apps</div>
                {selectedCompanies.map((item) => {
                      const {company='',package:packageName} = item;
                  return (
                    <div key={packageName} className="companiesTag">
                      <p>{company}</p>
                      <span
                        className="close"
                        onClick={() => {
                          updateCompanies(item);
                        }}
                      >
                        <Close size={10} />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {selecedTab === BROWSE && (
          <div className="mT15">
            <Button
              className="submitButton"
              disabled={(isEmpty(formState.sector) && formState.dateValue) || isLoadingCompany}
              onClick={handlerCompany}
              loading={isLoadingCompany}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    );
  };

  const renderRunReports = () => {
    const isDisabled = isEmpty(selectedCompanies) || isLoadingRunReport;
    return (
      (!isEmpty(companies) || selecedTab === SEARCH) && (
        <div className="plr ">
          <Button
            className={isDisabled ? "submitButton" : "runReports"}
            disabled={isDisabled}
            loading={isLoadingRunReport}
            onClick={handlerRunReports}
          >
            RUN REPORTS
          </Button>
        </div>
      )
    );
  };

  const renderBrowse = () => {
    return (
      <React.Fragment>
        <div className="plr">
          {renderFilterDate()}
          {renderFilter()}
        </div>
        {selecedTab === BROWSE && !isEmpty(companies) && (
          <React.Fragment>
            <CompanyList
              companyName={formState.sector}
              companies={companies}
              onClick={updateCompanies}
              selecedCompanies={selectedCompanies}
              addFavouriteCompanyName={addFavouriteCompanyName}
              showFavouritestab={true}
            />
          </React.Fragment>
        )}
        {errorCompanies && <div className="error">{errorCompanies}</div>}
        {renderRunReports()}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="plr">{renderTab()}</div>
      {renderBrowse()}
    </React.Fragment>
  );
};

InsightsBrowseAndSearch.propTypes = {
  formState: PropTypes.objectOf(PropTypes.any),
  updateFormState: PropTypes.func,
  sectorList: PropTypes.arrayOf(PropTypes.any),
  companies: PropTypes.arrayOf(PropTypes.any),
  handlerCompany: PropTypes.func,
  updateCompanies: PropTypes.func,
  handlerRunReports: PropTypes.func,
};

InsightsBrowseAndSearch.defaultProps = {
  sectorList: [],
  formState: {},
  updateFormState: noop,
  handlerCompany: noop,
  companies: [],
  updateCompanies: noop,
  handlerRunReports: noop,
};

const DateTime = styled.div`
  display: flex;

  .react-datepicker-wrapper {
    width: 140px;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  input {
    outline: 0;
    transform: rotateZ(0);
    min-width: 130px;
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: 0.78571429em 1em 0.78571429em 1em;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
  }
`;

const StyledUnOrderList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const StyledUnOrderListItem = styled.li`
  cursor: pointer;
  color: #fff;
  position: relative;
  font-weight: 600;
  padding: 10px 40px 8px 40px;
  background-color: ${(props) => (props.active ? "#f5f6f8" : "#718486")};
  color: ${(props) => (props.active ? "#718486" : "#f5f6f8")};
  text-transform: uppercase;
  display: inline-block;
  border: ${(props) => (props.active ? " 2px solid #718486" : "")};
  border-bottom: none;
`;

export default InsightsBrowseAndSearch;
