// constants
import { INSIGHT_PLUS, DATABASE, REPORTS, SETTINGS,SETTING ,FAVOURITES, INSIGHT, INSIGHT_TABLE } from "../constants";

import InsightPlus from "../icon/InsightPlus";
import Database from "../icon/Database";
import Reports from "../icon/Reports";
import Settings from "../icon/Settings";
import Insight from "../icon/Insight";
import Favourites from "../icon/Favourites";

export const renderIcon = (name, path, location) => {
  const activeUrl = location.pathname === path;
  const activeColor = activeUrl ? "#ffc82d" : "#999";
  switch (name) {
    case INSIGHT_PLUS:
      return <InsightPlus size={30} fill={activeColor} />;
    case DATABASE:
      return <Database size={30} fill={activeColor} />;
    case SETTING:
      return <Settings size={30} fill={activeColor} />;
    case REPORTS:
      return <Reports size={30} fill={activeColor} />;

    default:
      break;
  }
};

export const renderInsightHeaderIcon = (name, fill) => {
  switch (name) {
    case INSIGHT_PLUS:
      return <InsightPlus size={25} fill={fill} />;
    case INSIGHT_TABLE:
      return <InsightPlus size={25} fill={fill} />;
    case INSIGHT:
      return <Insight size={25} fill={fill} />;
    case FAVOURITES:
      return <Favourites size={25} fill={fill} />;

    default:
      break;
  }
};

export const dateTimeHelper = (range = 0) => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const endDay = new Date(y, m -1, 1);

  switch (range) {
    case 1:
      return { stateDate: new Date(y, m - 1, 1), endDay };
    case 3:
      return { stateDate: new Date(y, m - 3, 1), endDay };
    case 6:
      return { stateDate: new Date(y, m - 6, 1), endDay };
    case 9:
      return { stateDate: new Date(y, m - 9, 1), endDay };
    case 12:
      return { stateDate: new Date(y, m - 12, 1), endDay };
    default:
      return { stateDate: "", endDay: "" };
  }
};
