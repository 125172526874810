import React from "react";
import PropTypes from "prop-types";

const Mobile = (props) => {
  const { size, fill } = props;
  return (
    <svg height={size} width={size} viewBox="0 0 15.65 25.96">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={fill}
            d="M15.65,11.72V2.46A2.43,2.43,0,0,0,13.19,0H2.43A2.45,2.45,0,0,0,0,2.46v21A2.48,2.48,0,0,0,2.43,26H13.19a2.46,2.46,0,0,0,2.46-2.46v-10a1,1,0,0,0-.58-.89A1,1,0,0,0,15.65,11.72Zm-2,1.78v10a.5.5,0,0,1-.49.49H2.43A.49.49,0,0,1,2,23.5v-21A.48.48,0,0,1,2.43,2H13.19a.48.48,0,0,1,.49.5v9.26a1,1,0,0,0,.57.89A1,1,0,0,0,13.68,13.5Z"
          />
          <rect fill={fill} x="6.82" y="10.78" width="2" height="9" />
          <rect fill={fill} x="3.39" y="16.45" width="2" height="3.33" />
          <rect fill={fill} x="10.26" y="13.61" width="2" height="6.17" />
        </g>
      </g>
    </svg>
  );
};

Mobile.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Mobile.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Mobile);
