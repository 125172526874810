import { isEmpty } from "lodash";
import FiltersSummery from "./component/FiltersSummery";
import useFavourites from "./hook/useFavourites";

const Favourites = (props) => {
  const { updateForm, handlerHeaderTab, setPreFillTab, setCompanyColorMapping } = props;
  const { favouriteItems = [], favouritesSearchDeleta,emptyFavouritesErrorMessage='',emptyCompanyErrorMessage='' } = useFavourites();

  const renderFilterItem = () => {
    if (!isEmpty(favouriteItems)) {
      return favouriteItems.map((Item) => {
        const { filters = {}, name = "", uuid = "" } = Item;
        return (
          <FiltersSummery
            title={name}
            id={uuid}
            favouritesSearchDeleta={favouritesSearchDeleta(uuid)}
            searchInsightFilters={filters}
            handlerHeaderTab={handlerHeaderTab}
            setPreFillTab={setPreFillTab}
            updateForm={updateForm}
            setCompanyColorMapping={setCompanyColorMapping}
          />
        );
      });
    }
    return <div className='marginTop40'>{emptyFavouritesErrorMessage}</div>;
  };

  return <div className="favourites">{renderFilterItem()}</div>;
};

export default Favourites;
