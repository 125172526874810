import React from 'react'
import PropTypes from "prop-types";

import ChevronRight from '../../../../icon/ChevronRight';
import DoubleChevronRight from '../../../../icon/DoubleChevronRight';



export default function Pagination({table = {}}) {
  return (
    <>
    <div className="flex items-center gap-2 mt-5 pagination justify-between">
        <div className='flex items-center space-x-2'>
          <button
            type="button"
            className=""
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <DoubleChevronRight rotate={-1}  />
          </button>
          <button
            type="button"
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronRight rotate={-1}  />
          </button>
          <button
            type="button"
            className="rounded p-1 border-gray-300"
            onClick={() => {
              table.nextPage()}
            }
            disabled={!table.getCanNextPage()}
          >
            <ChevronRight rotate={1} />
          </button>
          <button
            type="button"
            className="rounded p-1 border-gray-300"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <DoubleChevronRight rotate={1} />
          </button>
        </div>
        <div className='flex items-center justify-center space-x-2'>
          <span className="flex items-center gap-1 border-gray-300">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className="border p-1 rounded w-16 border-gray-300"
            />
          </span>
        </div>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className='font-xs mt-5 text-gray-400'>Total {table.getPrePaginationRowModel().rows.length} records found</div>
    </>
  )
}

Pagination.propTypes = {
  table: PropTypes.object
}



