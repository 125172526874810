export const MONTH = "month";
export const USER_STATIC = "UserStatic";
export const limit = "100";
export const TIER = "tier";
export const GENDER = "gender";
export const AGE = "age";
export const EQUALS = "equals";

export const REACH = "Reach <span>(percentage)</span>";
export const TOTAL_SESSION_TIME = "Total Session time <span>(mins)</span>";
export const AVERAGE_SESSION_TIME = "Average session time <span>(mins)</span>";
export const OPEN_RATE = "Open rate";
export const MONTHLY_ACTIVE_USERS = "Monthly active users <span>(percentage)</span>";
export const WEEKLY_ACTIVE_USERS = "Weekly active users <span>(percentage)</span>";
export const DAILY_ACTIVE_USERS = "Daily active users <span>(percentage)</span>";
export const UNINSTALL_RATE = "Uninstall rate <span>(percentage)</span>";
export const AVERAGE_ORDER_VALUE = "Average order value <span>(inr)</span>";
export const AVERAGE_ORDER_FREQUENCY = " Average order frequency <span>(inr)</span>";
export const AVERAGE_REVENUE_PER_USER = "Average revenue per user <span>(inr)</span>";

export const dateTimeDimensions = {
  dimension: "",
  dateRange: [], // add filter Date
  granularity: "month",
};
export const allfilters = {
  dimension: "",
  values: [], // add Company ReachMonthly
  operator: EQUALS,
};

export const complexFiltersItem = {
  dimension: "",
  operator: EQUALS,
  values: [],
};

export const commonFilter = {
  measures: [],
  dimensions: [],
  timeDimensions: [],
  filters: [],
  complexFilters: [],
};
