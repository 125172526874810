import React from "react";
import PropTypes from "prop-types";

const Pdf = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      fill={fill}
      viewBox="0 0 408.2 486.91"
    >
   
   <g id="Layer_2" data-name="Layer 2"><g id="_ÎÓÈ_1" data-name="—ÎÓÈ_1"><path fill={fill} d="M3.41,96.39h59q19.27,0,28.86,8t9.58,22.89q0,15.26-10.44,23.85t-31.91,8.59H39v37.39H3.41Zm35.62,43h8.69q10.26,0,14.41-3.13a9.61,9.61,0,0,0,4.15-8,10.5,10.5,0,0,0-3.6-8q-3.6-3.3-13.55-3.3H39Z"/><path fill={fill} d="M119.83,96.39h52.69q15.58,0,25.17,3.71a40.77,40.77,0,0,1,15.85,10.66,42.2,42.2,0,0,1,9.08,16.15,66.26,66.26,0,0,1,2.82,19.51q0,16.16-4.19,25.05a41.79,41.79,0,0,1-11.62,14.92,39.57,39.57,0,0,1-16,8,93.19,93.19,0,0,1-21.14,2.75H119.83Zm35.47,22.82v55.05H164q11.11,0,15.82-2.17a15.38,15.38,0,0,0,7.35-7.56q2.67-5.39,2.67-17.48,0-16-5.95-21.93t-19.73-5.91Z"/><path fill={fill} d="M244.24,96.39h87.68V118H279.85v17.59h44.48V156H279.85v41.17H244.24Z"/><polygon fill={fill} points="259.89 486.91 0.92 486.91 0.92 257.11 41.88 257.11 41.88 445.96 242.53 445.96 367.25 317.08 367.25 40.95 0 40.95 0 0 408.2 0 408.2 333.65 259.89 486.91"/><polygon fill={fill} points="214.34 395.44 173.39 395.44 173.39 266.16 303.07 266.16 303.07 307.12 214.34 307.12 214.34 395.44"/></g></g>
    </svg>
  );
};


Pdf.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Pdf.defaultProps = {
  size: 12,
  fill: "#e73c1e",
};

export default React.memo(Pdf);
