import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import moment from "moment";

import {
  MONTH,
  USER_STATIC,
  EQUALS,
  TIER,
  GENDER,
  AGE,
  dateTimeDimensions,
  allfilters,
  commonFilter,
  TOTAL_SESSION_TIME,
  AVERAGE_SESSION_TIME,
  OPEN_RATE,
} from "../../../constants/filter";

const mapItemComplexFiltersItem = [TIER, GENDER, AGE];

const conversionData = (date = "") => {
  const getDate = new Date(date);
  const day = ("0" + getDate.getDate()).slice(-2);
  const month = ("0" + (getDate.getMonth() + 1)).slice(-2);
  const year = getDate.getFullYear();
  return `${year}-${month}-${day}`;
};

export const dataTimeFilter = (stateDate = "", endDate = "") => {
  const getStartDate = conversionData(stateDate);
  const getEndDate = conversionData(endDate);
  const dateTime = [getStartDate, getEndDate];
  return dateTime;
};

export const dataSet = (item = {}, filterType = "") => {
  const newComplexFiltersItem = [];

  mapItemComplexFiltersItem.forEach((filterItem) => {
    const getItem = get(item, filterItem, []);
    if (!isEmpty(getItem)) {
      const item = {
        dimension: `${USER_STATIC}.${filterItem}`,
        operator: EQUALS,
        values: getItem,
      };
      newComplexFiltersItem.push(item);
    }
  });

  return newComplexFiltersItem;
};

export const sessionDataSet = (item = {}) => {
  const { stateDate = "", endDate = "", companies } = item;
  const measures = [
    "CompanySessionMonthly.opens_numerator",
    "CompanySessionMonthly.avg_session_time_numerator",
    "CompanySessionMonthly.total_session_time_numerator",
    "CompanySessionMonthly.distinct_users",
  ];

  const withOutComplexFilterMeasures = [
    "CompanySessionMonthly.opens",
    "CompanySessionMonthly.avg_session_time",
    "CompanySessionMonthly.total_session_time",
  ];
  const dimensions = ["CompanySessionMonthly.sector", "CompanySessionMonthly.company"];
  const dateTimeDimensionsValue = "CompanySessionMonthly.dateLabel";
  const filtersDimensions = "CompanySessionMonthly.package";
  const getDateTime = dataTimeFilter(stateDate, endDate);

  const complexFiltersItem = dataSet(item);
  const finalItem = Object.create(commonFilter);
  const getAllfilters = Object.create(allfilters);
  getAllfilters.dimension = filtersDimensions;
  const convertTocompaniesPackage = companies.map(item=>{return item.package})
  getAllfilters.values = convertTocompaniesPackage
  getAllfilters.operator = EQUALS;
  finalItem.filters = [getAllfilters];
  const timeDimensionsData = Object.create(dateTimeDimensions);
  timeDimensionsData.dimension = dateTimeDimensionsValue;
  timeDimensionsData.dateRange = getDateTime;
  timeDimensionsData.granularity = MONTH;
  finalItem.measures = isEmpty(complexFiltersItem) ? withOutComplexFilterMeasures : measures;
  finalItem.timeDimensions = [timeDimensionsData];
  finalItem.dimensions = dimensions;
  finalItem.complexFilters = complexFiltersItem;
  return finalItem;
};

export const staticKey = (name = "") => {
  let withOutComplexFilterMeasures = [];
  let dimensions = [];
  let measures = [];
  let timeDimensions = [];
  let filtersDimensions = [];
  switch (name) {
    case "reachMonthly":
      withOutComplexFilterMeasures = ["CompanyReachMonthly.reach"];
      measures = ["CompanyReachMonthly.numerator", "CompanyReachMonthly.denominator"];
      dimensions = ["CompanyReachMonthly.sector", "CompanyReachMonthly.company"];
      timeDimensions = "CompanyReachMonthly.dateLabel";
      filtersDimensions = "CompanyReachMonthly.package";
      return {
        measures,
        withOutComplexFilterMeasures,
        dimensions,
        timeDimensions,
        filtersDimensions,
      };

    case "monthlyActiveUsers":
      measures = [
        "CompanyMonthlyActiveUsers.distinct_users_in_session_company",
        "CompanyMonthlyActiveUsers.distinct_users_in_company",
      ];
      withOutComplexFilterMeasures = ["CompanyMonthlyActiveUsers.monthly_active_users"];
      dimensions = ["CompanyMonthlyActiveUsers.sector", "CompanyMonthlyActiveUsers.company"];
      timeDimensions = "CompanyMonthlyActiveUsers.dateLabel";
      filtersDimensions = "CompanyMonthlyActiveUsers.package";
      return {
        measures,
        withOutComplexFilterMeasures,
        dimensions,
        timeDimensions,
        filtersDimensions,
      };

    case "weeklyAvtiveUser":
      withOutComplexFilterMeasures = [
        "CompanyWeeklyActiveUsers.distinct_users_in_session_company",
        "CompanyWeeklyActiveUsers.distinct_users_in_company",
      ];
      measures = [
        "CompanyWeeklyActiveUsers.distinct_users_in_session_company",
        "CompanyWeeklyActiveUsers.distinct_users_in_company",
      ];
      dimensions = ["CompanyWeeklyActiveUsers.sector", "CompanyWeeklyActiveUsers.company"];
      timeDimensions = "CompanyWeeklyActiveUsers.dateLabel";
      filtersDimensions = "CompanyWeeklyActiveUsers.package";
      return {
        measures,
        withOutComplexFilterMeasures,
        dimensions,
        timeDimensions,
        filtersDimensions,
      };

    case "dailyActiveUser":
      withOutComplexFilterMeasures = [
        "CompanyDailyActiveUsers.distinct_users_in_session_company",
        "CompanyDailyActiveUsers.distinct_users_in_company",
      ];
      measures = [
        "CompanyDailyActiveUsers.distinct_users_in_session_company",
        "CompanyDailyActiveUsers.distinct_users_in_company",
      ];
      dimensions = ["CompanyDailyActiveUsers.sector", "CompanyDailyActiveUsers.company"];
      timeDimensions = "CompanyDailyActiveUsers.dateLabel";
      filtersDimensions = "CompanyDailyActiveUsers.package";
      return {
        measures,
        withOutComplexFilterMeasures,
        dimensions,
        timeDimensions,
        filtersDimensions,
      };

    case "averageRevenuePerUser":
      withOutComplexFilterMeasures = [
        "CompanyAverageRevenuePerUser.arpu",
        "CompanyAverageRevenuePerUser.distinct_users_in_average_order_frequency",
      ];
      measures = [
        "CompanyAverageRevenuePerUser.arpu",
        "CompanyAverageRevenuePerUser.distinct_users_in_average_order_frequency",
      ];
      dimensions = ["CompanyAverageRevenuePerUser.sector", "CompanyAverageRevenuePerUser.company"];
      timeDimensions = "CompanyAverageRevenuePerUser.dateLabel";
      filtersDimensions = "CompanyAverageRevenuePerUser.package";
      return {
        measures,
        withOutComplexFilterMeasures,
        dimensions,
        timeDimensions,
        filtersDimensions,
      };

    case "averageOrderFrequency":
      withOutComplexFilterMeasures = [
        "CompanyAverageOrderFrequency.average_order_frequency",
        "CompanyAverageOrderFrequency.distinct_users_in_average_order_frequency",
      ];
      measures = [
        "CompanyAverageOrderFrequency.average_order_frequency",
        "CompanyAverageOrderFrequency.distinct_users_in_average_order_frequency",
      ];
      dimensions = ["CompanyAverageOrderFrequency.sector", "CompanyAverageOrderFrequency.company"];
      timeDimensions = "CompanyAverageOrderFrequency.dateLabel";
      filtersDimensions = "CompanyAverageOrderFrequency.package";
      return {
        measures,
        withOutComplexFilterMeasures,
        dimensions,
        timeDimensions,
        filtersDimensions,
      };
    case "averageOrderValue":
      withOutComplexFilterMeasures = [
        "CompanyAverageOrderValue.average_order_value",
        "CompanyAverageOrderValue.distinct_users_in_average_order_value",
      ];
      measures = [
        "CompanyAverageOrderValue.average_order_value",
        "CompanyAverageOrderValue.distinct_users_in_average_order_value",
      ];
      dimensions = ["CompanyAverageOrderValue.sector", "CompanyAverageOrderValue.company"];
      timeDimensions = "CompanyAverageOrderValue.dateLabel";
      filtersDimensions = "CompanyAverageOrderValue.package";
      return {
        measures,
        withOutComplexFilterMeasures,
        dimensions,
        timeDimensions,
        filtersDimensions,
      };

    case "uninstall":
      withOutComplexFilterMeasures = ["CompanyUninstallRate.uninstall_rate"];
      measures = [
        "CompanyUninstallRate.previous_month_distinct_users",
        "CompanyUninstallRate.current_month_distinct_users",
      ];
      dimensions = ["CompanyUninstallRate.sector", "CompanyUninstallRate.company"];
      timeDimensions = "CompanyUninstallRate.dateLabel";
      filtersDimensions = "CompanyUninstallRate.package";
      return {
        measures,
        withOutComplexFilterMeasures,
        dimensions,
        timeDimensions,
        filtersDimensions,
      };

    default:
      break;
  }
};

export const convertFormData = (item = {}, type = "") => {
  const { stateDate = "", endDate = "", companies = [] } = item;
  const constantsKey = staticKey(type) || {};
  const {
    measures = [],
    withOutComplexFilterMeasures = [],
    dimensions = [],
    timeDimensions: dateTimeDimensionsValue = [],
    filtersDimensions = "",
  } = constantsKey;

  const getDateTime = dataTimeFilter(stateDate, endDate);
  const complexFiltersItem = dataSet(item);
  const finalItem = Object.create(commonFilter);
  const getAllfilters = Object.create(allfilters);
  getAllfilters.dimension = filtersDimensions;
  const convertTocompaniesPackage = companies.map(item=>{return item.package})
  getAllfilters.values = convertTocompaniesPackage
  getAllfilters.operator = EQUALS;
  finalItem.filters = [getAllfilters];
  const timeDimensionsData = Object.create(dateTimeDimensions);
  timeDimensionsData.dimension = dateTimeDimensionsValue;
  timeDimensionsData.dateRange = getDateTime;
  timeDimensionsData.granularity = MONTH;
  finalItem.measures = isEmpty(complexFiltersItem) ? withOutComplexFilterMeasures : measures;
  finalItem.timeDimensions = [timeDimensionsData];
  finalItem.dimensions = dimensions;
  finalItem.complexFilters = complexFiltersItem;

  return finalItem;
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const chartjsSingalItem = {
  label: "",
  data: [],
  fill: true,
  backgroundColor: "rgba(75,192,192,0.2)",
  borderColor: "rgba(75,192,192,1)",
};

export const renderBgcolor = () => {
  const x = Math.floor(Math.random() * 256);
  const y = Math.floor(Math.random() * 256);
  const z = Math.floor(Math.random() * 256);
  const bgColor = "rgb(" + x + "," + y + "," + z + ")";
  return bgColor;
};

export const monthsList = (d1, d2) => {
  var dateStart = moment(d1);
  var dateEnd = moment(d2);
  var interim = dateStart.clone();
  var timeValues = [];

  while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
    timeValues.push(interim.format("YYYY-MMM"));
    interim.add(1, "month");
  }
  return timeValues;
};

export const createDataset = (data = [], item, title = "", companyColorMapping = [], maxDate = "") => {
  const { stateDate = "", endDate = "" } = item;
  const months = monthsList(stateDate, maxDate);

  let avgSessionTime = {};
  let totalSessionTime = {};
  let monthsSessionTime = {};

  avgSessionTime.labels = months;
  totalSessionTime.labels = months;
  monthsSessionTime.labels = months;
  avgSessionTime.datasets = [];
  totalSessionTime.datasets = [];
  monthsSessionTime.datasets = [];

  let monthsSessionTimeData = [];
  let avgSessionTimeData = [];
  let totalSessionTimeData = [];
  Object.keys(data).forEach((childItem) => {
    const currentItem = data[childItem] || [];
    const getBgColor = companyColorMapping.find((item) => item.company === childItem) || {};
    const { color = "" } = getBgColor;
    const bgColor = color;
    const singalItem = Object.create(chartjsSingalItem);
    const singalItem2 = Object.create(chartjsSingalItem);
    const singalItem3 = Object.create(chartjsSingalItem);
    singalItem.label = childItem;
    singalItem.backgroundColor = bgColor;
    singalItem.borderColor = bgColor;
    singalItem.fill = false;
    singalItem.lineTension = "0.5";
    singalItem.borderWidth = 2;
    //  2
    singalItem2.label = childItem;
    singalItem2.backgroundColor = bgColor;
    singalItem2.borderColor = bgColor;
    singalItem2.fill = false;
    singalItem2.lineTension = "0.5";
    singalItem2.borderWidth = 2;
    // 3
    singalItem3.label = childItem;
    singalItem3.backgroundColor = bgColor;
    singalItem3.borderColor = bgColor;
    singalItem3.fill = false;
    singalItem3.lineTension = "0.5";
    singalItem3.borderWidth = 2;
    let portData = [];
    let portData1 = [];
    let portData2 = [];
    // get Data
    months.forEach((cItem, index) => {
      const getItem =
        currentItem.find((chidItem) => {
          const { session_monthly__date_label_month = "" } = chidItem;
          const convertData = moment(session_monthly__date_label_month).format("YYYY-MMM");
          if (convertData === cItem) {
            return chidItem;
          }
        }) || {};

      const {
        session_monthly__opens = 0,
        session_monthly__total_session_time = 0,
        session_monthly__avg_session_time,
      } = getItem;

      portData.splice(index, 0, session_monthly__opens || 0);
      portData1.splice(index, 0, session_monthly__avg_session_time || 0);
      portData2.splice(index, 0, session_monthly__total_session_time || 0);
    });
    singalItem.data = portData;
    singalItem2.data = portData1;
    singalItem3.data = portData2;
    monthsSessionTimeData.push(singalItem);
    avgSessionTimeData.push(singalItem2);
    totalSessionTimeData.push(singalItem3);
  });
  monthsSessionTime.name = OPEN_RATE;
  monthsSessionTime.order = 3;
  avgSessionTime.name = AVERAGE_SESSION_TIME;
  avgSessionTime.order = 4;
  totalSessionTime.name = TOTAL_SESSION_TIME;
  totalSessionTime.order = 2;
  monthsSessionTime.datasets = monthsSessionTimeData;
  avgSessionTime.datasets = avgSessionTimeData;
  totalSessionTime.datasets = totalSessionTimeData;
  totalSessionTime.maxDate = maxDate;
  avgSessionTime.maxDate = maxDate;
  monthsSessionTime.maxDate = maxDate;
  const session = [monthsSessionTime, avgSessionTime, totalSessionTime];
  return session;
};

export const createSingalObjectDataset = (
  data = [],
  item,
  pageKey = {},
  objectType = false,
  companyColorMapping = [],
  maxDate = ""
) => {
  const { stateDate = "", endDate = "" } = item;
  const months = monthsList(stateDate, maxDate);

  const { keyMonthName = "", pageTitle = "", keyGetItem = "" } = pageKey;

  let monthlyActiveUsers = {};
  monthlyActiveUsers.labels = months;
  monthlyActiveUsers.datasets = [];

  let monthlyActiveUsersData = [];
  Object.keys(data).forEach((childItem) => {
    const currentItem = data[childItem] || [];
    const getBgColor = companyColorMapping.find((item) => item.company === childItem) || {};
    const { color = "" } = getBgColor;
    const singalItem = Object.create(chartjsSingalItem);

    singalItem.label = childItem;
    singalItem.backgroundColor = color;
    singalItem.borderColor = color;
    singalItem.fill = false;
    singalItem.lineTension = "0.2";
    singalItem.borderWidth = 2;

    let portData = [];
    // get Data
    months.forEach((cItem, index) => {
      const getItem =
        currentItem.find((currentChildItem) => {
          const getMonthName = get(currentChildItem, keyMonthName, "");
          const convertData = moment(getMonthName).format("YYYY-MMM");
          if (convertData === cItem) {
            return currentChildItem;
          }
        }) || {};
      const getItemName = get(getItem, keyGetItem, 0);
      portData.splice(index, 0, getItemName || 0);
    });
    singalItem.data = portData;
    monthlyActiveUsersData.push(singalItem);
  });
  monthlyActiveUsers.name = pageTitle;
  monthlyActiveUsers.maxDate = maxDate;
  monthlyActiveUsers.datasets = monthlyActiveUsersData;
  if (objectType) {
    const session = monthlyActiveUsers;
    return session;
  }
  return [monthlyActiveUsers];
};
