import React from "react";
import Header from "./component/Header/container";
import { Toaster } from "react-hot-toast";
// hook
import useInsights from "./hook/useInsights";
import useInsightsPlus from "./hook/useInsightsPlus";

// Component
import InsightsBrowseAndSearch from "../common/filter/insightsBrowseAndSearch";
import InsightsDetailPage from "./component/insightsDetailPage/container";
import InsightsPlusFilter from "../common/filter/insightsPlus";
import InsightsPlusTableFilter from "../common/filter/insightsPlusTable";

import Favourites from "./component/favourites";
import LeftPanalFavourites from "./component/favourites/component/LeftPanal";

import { INSIGHT, FAVOURITES, INSIGHT_PLUS, INSIGHT_TABLE, MODEL } from "../../constants";
import InsightPlusTablePage from "./component/InsightPlusTablePage/container";

const Insights = (props) => {
  const {
    setInsightGraph,
    insightGtaph,
    setEmptyInsightGraph,
    setFilterTab,
    setSearchInsightFilters,
    filterTab,
    setUninstall,
    setReachGraph,
    setMonthlyActiveUserGraph,
    setDailyActiveUserGraph,
    setAverageRevenuePerUserGraph,
    setAverageOrderFrequencyGraph,
    setAverageOrderValueGraph,
    setWeeklyActiveUserGraph,
    setAverageSessionTimeGraph,
    setSessionOpenRateGraph,
    setTotalSessiontimeGraph,
    setInsightTableData
  } = props;
  const {
    headerMenu = [],
    updateFormState,
    formState,
    sectorList = [],
    handlerCompany,
    companyList,
    updateCompanies,
    setFormState,
    handlerRunReports,
    updateFormStateWithKey,
    henderDropdownValue,
    isLoadingCompany,
    isLoadingRunReport,
    headerCurrentItem,
    handlerHeaderTab,
    errorState,
    setCompanyList,
    addFavouriteCompanyName,
    setCompanyColorMapping,
    emptyDetailPage,
    handleRemoveFilterAndResetDate,
    handlerRunReportsInsightsPlus,
    uploadPDFFile,
    preFillTab,
    setPreFillTab,
  } = useInsights({
    insightGtaph,
    setInsightGraph,
    setEmptyInsightGraph,
    setSearchInsightFilters,
    searchInsightFilters: setSearchInsightFilters,
    setUninstall,
    setReachGraph,
    setMonthlyActiveUserGraph,
    setDailyActiveUserGraph,
    setAverageRevenuePerUserGraph,
    setAverageOrderFrequencyGraph,
    setAverageOrderValueGraph,
    setWeeklyActiveUserGraph,
    setAverageSessionTimeGraph,
    setSessionOpenRateGraph,
    setTotalSessiontimeGraph,
    setInsightTableData
  });

  const {
    insightsPlusFormState,
    updateFormInsightsPlus,
    isLoading,
    isLoadingRunModal,
    handlerSubmit,
    setInsightsPlusState,
    errorStateInsitePlus,
    emptryState,
    updateCompaniesList,
    companyList: getcompanyList,
    setCompanyList: removeCompanyList,
    companyColorMapping,
    getSectorList,
    setSectorList,
  } = useInsightsPlus({ filterTab, setInsightGraph, setEmptyInsightGraph, setInsightTableData });

  const renderTabInfo = () => {
    switch (headerCurrentItem) {
      case INSIGHT:
        return (
          <div className="w25 filterBox">
            <InsightsBrowseAndSearch
              handlerCompany={handlerCompany}
              formState={formState}
              updateFormState={updateFormState}
              sectorList={sectorList}
              companies={companyList}
              updateCompanies={updateCompanies}
              setFormState={setFormState}
              handlerRunReports={handlerRunReports}
              updateFormStateWithKey={updateFormStateWithKey}
              henderDropdownValue={henderDropdownValue}
              isLoadingCompany={isLoadingCompany}
              isLoadingRunReport={isLoadingRunReport}
              setEmptyInsightGraph={setEmptyInsightGraph}
              setFilterTab={setFilterTab}
              setSearchInsightFilters={setSearchInsightFilters}
              errorState={errorState}
              setCompanyList={setCompanyList}
              addFavouriteCompanyName={addFavouriteCompanyName}
              setCompanyColorMapping={setCompanyColorMapping}
              emptyDetailPage={emptyDetailPage}
              preFillTab={preFillTab}
              setPreFillTab={setPreFillTab}
            />
          </div>
        );

      case INSIGHT_PLUS:
        return (
          <div className="w25 filterBox insightplus">
            <InsightsPlusFilter
              sectorList={getSectorList}
              onClickSubmit={handlerSubmit}
              formState={insightsPlusFormState}
              onClickFormData={updateFormInsightsPlus}
              isLoading={isLoading}
              setInsightsPlusState={setInsightsPlusState}
              isLoadingRunModal={isLoadingRunModal}
              setEmptyInsightGraph={setEmptyInsightGraph}
              setFilterTab={setFilterTab}
              setSearchInsightFilters={setSearchInsightFilters}
              errorStateInsitePlus={errorStateInsitePlus}
              emptryState={emptryState}
              addFavouriteCompanyName={addFavouriteCompanyName}
              updateCompaniesList={updateCompaniesList}
              companyList={getcompanyList}
              removeCompanyList={removeCompanyList}
              handlerRunReportsInsightsPlus={handlerRunReportsInsightsPlus}
              isLoadingRunReport={isLoadingRunReport}
              companyColorMapping={companyColorMapping}
              emptyDetailPage={emptyDetailPage}
              setSectorList={setSectorList}
            />
          </div>
        );

        case INSIGHT_TABLE:
          return (
            <div className="w25 filterBox insightplus">
              <InsightsPlusTableFilter
                sectorList={sectorList}
                onClickSubmit={handlerSubmit}
                formState={insightsPlusFormState}
                onClickFormData={updateFormInsightsPlus}
                isLoading={isLoading}
                setInsightsPlusState={setInsightsPlusState}
                isLoadingRunModal={isLoadingRunModal}
                setEmptyInsightGraph={setEmptyInsightGraph}
                setFilterTab={setFilterTab}
                setSearchInsightFilters={setSearchInsightFilters}
                errorStateInsitePlus={errorStateInsitePlus}
                emptryState={emptryState}
                addFavouriteCompanyName={addFavouriteCompanyName}
                updateCompaniesList={updateCompaniesList}
                companyList={getcompanyList}
                removeCompanyList={removeCompanyList}
                handlerRunReportsInsightsPlus={handlerRunReportsInsightsPlus}
                isLoadingRunReport={isLoadingRunReport}
                companyColorMapping={companyColorMapping}
                emptyDetailPage={emptyDetailPage}
                setSectorList={setSectorList}
              />
            </div>
          );

      case FAVOURITES:
        return (
          <div className="favouriteLeft">
            <div className="w25 filterBox">
              <LeftPanalFavourites
                handlerHeaderTab={handlerHeaderTab}
                updateForm={setFormState}
                addFavouriteCompanyName={addFavouriteCompanyName}
                preFillTab={preFillTab}
                setPreFillTab={setPreFillTab}
                setCompanyColorMapping={setCompanyColorMapping}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };
  
  const renderDetailPage = () => {
    if (headerCurrentItem === FAVOURITES) {
      return (
        <div>
          <Favourites
            setCompanyColorMapping={setCompanyColorMapping}
            preFillTab={preFillTab}
            setPreFillTab={setPreFillTab}
            updateForm={setFormState}
            handlerHeaderTab={handlerHeaderTab}
          />
        </div>
      );
    }
    
    if (headerCurrentItem === INSIGHT_TABLE && filterTab === MODEL) {
      return (
        <div className="pd20 h100">
          <InsightPlusTablePage isLoading={isLoading} />
        </div>
      );  
    }

    return (
      <InsightsDetailPage
        isLoadingRunReport={isLoadingRunReport}
        uploadPDFFile={uploadPDFFile}
        handleRemoveFilterAndResetDate={handleRemoveFilterAndResetDate}
      />
    );
  };

  return (
    <div>
      <Header menu={headerMenu} onClickTab={handlerHeaderTab} />
      <div className="outerContainer">
        <div className="container">
          {renderTabInfo()}
          <div className="w80">{renderDetailPage()}</div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Insights;
