import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducer/rootReducer";

//only local host used redux tool
const devTools =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || ((a) => a);

const configureStore = (preloadedState) =>
  createStore(rootReducer, preloadedState, compose(applyMiddleware(thunk), devTools));

//const configureStore = (preloadedState) => createStore(rootReducer, preloadedState, compose(applyMiddleware(thunk)));

const store = configureStore();

export default store;
