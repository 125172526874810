import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Insights from "./index";
import {
  setInsightGraph,  
  setEmptyInsightGraph,
  setFilterTab,
  setSearchInsightFilters,
  setUninstall,
  setReachGraph,
  setMonthlyActiveUserGraph,
  setDailyActiveUserGraph,
  setAverageRevenuePerUserGraph,
  setAverageOrderFrequencyGraph,
  setAverageOrderValueGraph,
  setWeeklyActiveUserGraph,
  setAverageSessionTimeGraph,
  setSessionOpenRateGraph,
  setTotalSessiontimeGraph,
  setInsightTableData
} from "../../action/insight";

const mapStateToProps = ({ bootstrapReducer, insightReducer }) => {
  const { filterTab = "", insightGtaph = [], reachGraph = {}, uninstall = {} } = insightReducer;
  return { filterTab, insightGtaph, uninstall, reachGraph };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setInsightGraph,
      setEmptyInsightGraph,
      setFilterTab,
      setSearchInsightFilters,
      setUninstall,
      setReachGraph,
      setMonthlyActiveUserGraph,
      setDailyActiveUserGraph,
      setAverageRevenuePerUserGraph,
      setAverageOrderFrequencyGraph,
      setAverageOrderValueGraph,
      setWeeklyActiveUserGraph,
      setAverageSessionTimeGraph,
      setSessionOpenRateGraph,
      setTotalSessiontimeGraph,
      setInsightTableData
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
