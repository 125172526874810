import { connect } from "react-redux";

import InsightPlusTablePage from "./index";

const mapStateToProps = ({ insightReducer = {} }) => {
  const { insightTableData = [] } = insightReducer;

  return { data: insightTableData };
};

export default connect(mapStateToProps)(InsightPlusTablePage);
