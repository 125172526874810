import React from "react";
import PropTypes from "prop-types";

const Reports = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={fill}
          d="M10.68,6.41a1,1,0,0,0,1,.6,4.87,4.87,0,0,1,2.47.48,1,1,0,0,0,.44.1,1,1,0,0,0,.71-.29l3.65-3.66a1,1,0,0,0,.29-.8,1,1,0,0,0-.43-.73A12,12,0,0,0,12,0,11.83,11.83,0,0,0,9.35.3a1,1,0,0,0-.67.52,1,1,0,0,0,0,.85Zm5.95-3.28L14.35,5.41A6.79,6.79,0,0,0,12.26,5L11,2.05A10,10,0,0,1,16.63,3.13ZM7.26,13.59a5,5,0,0,1,1.2-5.13l.11-.09A1,1,0,0,0,8.85,7.2l-2-4.75a1,1,0,0,0-.63-.57A1,1,0,0,0,5.34,2a12.48,12.48,0,0,0-1.83,1.5A12,12,0,0,0,1.36,17.56a1,1,0,0,0,.68.51.76.76,0,0,0,.21,0,1,1,0,0,0,.61-.22l4.07-3.19A1,1,0,0,0,7.26,13.59Zm-2.09-.06-2.54,2A10,10,0,0,1,4.93,4.93c.17-.18.36-.35.55-.52l1.27,3A6.93,6.93,0,0,0,5.17,13.53ZM21.88,5.19a1,1,0,0,0-.73-.42,1,1,0,0,0-.79.29L16.7,8.71a1,1,0,0,0-.19,1.14,5,5,0,0,1-7.2,6.37,1,1,0,0,0-1.15.05L4.1,19.46a1,1,0,0,0-.39.75,1,1,0,0,0,.34.78A12,12,0,0,0,21.88,5.19ZM19.07,19.07A10,10,0,0,1,6.34,20.24l2.53-2a7,7,0,0,0,9.72-8.61l2.28-2.28A10,10,0,0,1,19.07,19.07Z"
        />
      </g>
    </svg>
  );
};

Reports.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Reports.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Reports);
