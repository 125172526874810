// Libraries
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Insights from "./pages/insights/container";
import Reports from "./pages/reports";
import Setting from "./pages/setting";
import Database from "./pages/database";

import Login from "./pages/LoginForm/container";

import LeftNavigation from "../src/pages/component/leftNavigation/container";

const Router= (props) => {
  const {isLogin} =props;
  return (
    <BrowserRouter>
      <LeftNavigation />
      {!isLogin && 
      <Switch>
       <Route exact path="/login" component={Login} />
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
      }
      {isLogin && <Switch>
        <Route exact path="/insights" component={Insights} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/setting" component={Setting} />
        <Route exact path="/database" component={Database} />
        
        <Route path="*">
          <Redirect to="/insights" />
        </Route>
        </Switch>}
    </BrowserRouter>
  );
};

const mapStateToProps = ({ bootstrapReducer }) => {
  const {user={}} =bootstrapReducer;
  const {access_token=''} = user;
  const isLogin = !!access_token;
  return {isLogin};
};

export default connect(mapStateToProps)(Router);

