import React from "react";
import PropTypes from "prop-types";

const Logo = (props) => {
  const { size, fill, bgColor } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 199.02 199.03"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={bgColor}
            d="M199,99.51A99.51,99.51,0,1,1,99.51,0,99.52,99.52,0,0,1,199,99.51"
          />
          <path
            fill={fill}
            d="M69.67,112.56l-6,7.68-16.57,6.31s-4-1.45-5.7.45l3.26,2.51,23.19-6.08Z"
          />
          <path
            fill={fill}
            d="M118.93,101.79s6.75,13.9,2.38,19.5c-.45.77-1,1.54-1,1.54s-2.92-.92-4.07,1.53l5.45.62,5.68-11.13Z"
          />
          <path
            fill={fill}
            d="M131.13,76.68l0-2,17.51-30.44-1.25-2.45L121.15,78,81.32,104.08l.89-1.7L87.55,99l-6.93-4.42-.79-2L72,89.68l-4.91.54-3.39-1.36-.27,2.06-.76.38,2.86,5.18-3.38-2.63c0,2,1.94,4.09,1.94,4.09l6.23,11.86.27,2.91-1.94,11.46-24.2,6.35-2.25-1.73a5.08,5.08,0,0,0-2.81,1.83L48.33,132l25.48-3.69,8-4.87L99.91,121l17-21.62,2.78,1.84,8.76,12.51-4.37,8.57-1.26,4.47s-3.85,0-4,2.84h6.08l5.47-14L127.48,101l7.21-10.94ZM68.72,92.3a2.67,2.67,0,0,1,4,1.07Zm16.21,6.58-3.16,1.81L80,96Z"
          />
          <polygon
            fill={fill}
            points="170.42 87.06 169.41 82.44 169.74 82.37 170.27 83.75 175.55 78.31 174.4 77.58 169.19 78.71 169.27 80.2 168.94 80.27 167.94 75.67 168.27 75.61 168.82 76.99 180.2 74.52 180.11 73.03 180.45 72.95 181.45 77.55 181.11 77.62 180.57 76.23 174.94 77.46 181.24 81.41 181.53 79.56 181.87 79.49 182.65 83.1 182.31 83.17 181.38 82.03 177.03 79.26 170.75 85.87 170.75 86.98 170.42 87.06"
          />
          <path
            fill={fill}
            d="M171,100.12l-.33,0,.33-3.41.33,0,.49,1.62,11.65,5.76,0,.25L170.5,107.5l-.37,1.45-.34,0,.45-4.68.33,0,.08,1.33,3.91-1,.4-4.24-3.22-1.61Zm9.73,3-5.4-2.62-.39,4Z"
          />
          <polygon
            fill={fill}
            points="178.67 124.55 176.82 128.87 176.5 128.73 176.87 127.29 165.98 122.65 164.1 127.05 166.82 129.64 166.65 130.03 163.06 128.5 166.9 119.52 167.2 119.66 166.85 121.11 177.56 125.67 178.34 124.41 178.67 124.55"
          />
          <path
            fill={fill}
            d="M155.56,140.52l-.26-.22,2.24-2.6.25.22-.54,1.61L163.44,151l-.17.19-12.35-4.87-1.14.95-.26-.22,3.07-3.56.26.22-.7,1.13,3.76,1.44,2.77-3.22-1.7-3.18Zm6.19,8.08-2.89-5.27-2.62,3Z"
          />
          <path
            fill={fill}
            d="M140,170l-.29.18-2.07-3.39.29-.18,2.28,1.71a4.2,4.2,0,0,0,2.87-.64,3.39,3.39,0,0,0,1.65-2.53,5.41,5.41,0,0,0-.93-3.63l-1.52-2.5a5.68,5.68,0,0,0-2.75-2.52,3.27,3.27,0,0,0-3,.3,2.64,2.64,0,0,0-1.35,1.91,3.86,3.86,0,0,0,.67,2.54l.94,1.54,1.39-.59.18.3-4.08,2.5-.18-.3,1.15-1-.94-1.54a3.54,3.54,0,0,1-.41-2.91,5,5,0,0,1,2.43-2.79,5.23,5.23,0,0,1,4.56-.62,7.26,7.26,0,0,1,3.89,3.32,7.09,7.09,0,0,1,1.13,5,5.5,5.5,0,0,1-2.7,3.84,4.62,4.62,0,0,1-2.81.74Z"
          />
          <path
            fill={fill}
            d="M118.86,164.89l-.07-.32,3.33-.8.07.33-1.37,1L119.12,178l-.25.06-7.14-11.19-1.49.1-.08-.33,4.58-1.08.08.32-1.23.51,2.2,3.37,4.14-1,.48-3.57Zm.29,10.18.73-6L116,170Z"
          />
          <polygon
            fill={fill}
            points="89.98 175.93 90.99 179.16 93.64 179.32 94.35 167.52 92.89 167.19 92.92 166.85 97.61 167.14 97.59 167.47 96.11 167.62 95.39 179.43 98.04 179.59 99.43 176.5 99.9 176.52 99.68 180.09 89.29 179.46 89.5 175.9 89.98 175.93"
          />
          <path
            fill={fill}
            d="M76.11,170.15a7.94,7.94,0,0,1-1.44,2.37,6.26,6.26,0,0,1-2,1.5,5.63,5.63,0,0,1-4.7.1,6,6,0,0,1-2.06-1.37,5.62,5.62,0,0,1-1.25-2,7.74,7.74,0,0,1,3.61-9,5.55,5.55,0,0,1,2.3-.58,5.9,5.9,0,0,1,2.44.46A5.56,5.56,0,0,1,76.36,165a6.94,6.94,0,0,1-.25,5.12m-8.88-4.92-.92,2.28a5.69,5.69,0,0,0-.34,3.88,3.73,3.73,0,0,0,5.39,2.19,5.81,5.81,0,0,0,2.45-3l.93-2.29a5.8,5.8,0,0,0,.36-3.91,3.8,3.8,0,0,0-5.4-2.18,5.81,5.81,0,0,0-2.47,3.06"
          />
          <polygon
            fill={fill}
            points="29.02 106.77 30.14 111.36 29.81 111.43 29.25 110.07 24.11 115.64 25.28 116.34 30.46 115.08 30.34 113.59 30.67 113.52 31.78 118.08 31.46 118.16 30.87 116.79 19.56 119.55 19.68 121.03 19.35 121.12 18.23 116.55 18.57 116.46 19.14 117.84 24.73 116.48 18.34 112.68 18.1 114.54 17.76 114.62 16.89 111.03 17.22 110.95 18.19 112.06 22.6 114.73 28.71 107.96 28.69 106.85 29.02 106.77"
          />
          <path
            fill={fill}
            d="M28.15,93.77l.34,0-.24,3.41-.34,0-.52-1.61-11.8-5.46,0-.25,12.82-3.49.34-1.45.34,0-.33,4.7-.34,0-.11-1.33-3.88,1.1-.3,4.24,3.26,1.53ZM18.36,91l5.47,2.48.28-4Z"
          />
          <polygon
            fill={fill}
            points="19.78 69.71 21.5 65.33 21.83 65.46 21.5 66.91 32.51 71.25 34.27 66.8 31.47 64.29 31.64 63.89 35.26 65.32 31.68 74.4 31.37 74.28 31.69 72.82 20.86 68.55 20.11 69.83 19.78 69.71"
          />
          <path
            fill={fill}
            d="M42.45,53.08l.27.21L40.55,56l-.26-.22.5-1.61-6.5-11.26.17-.2L46.93,47.2l1.12-1,.27.22-3,3.64-.26-.21.67-1.15L42,47.37l-2.69,3.3L41,53.79ZM36,45.17l3,5.19,2.54-3.12Z"
          />
          <path
            fill={fill}
            d="M57.17,23.16l.28-.18,2.16,3.33-.29.18L57,24.85a4.15,4.15,0,0,0-2.85.72,3.4,3.4,0,0,0-1.58,2.57,5.36,5.36,0,0,0,1,3.6l1.59,2.45A5.73,5.73,0,0,0,58,36.65a3.27,3.27,0,0,0,3-.39,2.62,2.62,0,0,0,1.29-1.94,3.86,3.86,0,0,0-.74-2.52l-1-1.52-1.37.63-.2-.3L63,28l.19.3-1.13,1,1,1.51a3.54,3.54,0,0,1,.48,2.9,5,5,0,0,1-2.35,2.86,5.24,5.24,0,0,1-4.55.74,7.35,7.35,0,0,1-4-3.21,7.15,7.15,0,0,1-1.26-5A5.48,5.48,0,0,1,54,25.21a4.73,4.73,0,0,1,2.79-.82Z"
          />
          <path
            fill={fill}
            d="M78.64,27.67l.08.33-3.31.87-.08-.33,1.35-1L78.05,14.6l.24-.07,7.43,11,1.49-.14.08.32-4.55,1.2-.09-.32,1.22-.54-2.28-3.32-4.12,1.09-.39,3.58ZM78.1,17.5l-.59,6,3.89-1Z"
          />
          <polygon
            fill={fill}
            points="107.18 15.92 106.08 12.71 103.43 12.62 103.02 24.43 104.48 24.73 104.47 25.06 99.77 24.9 99.78 24.56 101.26 24.37 101.67 12.55 99.03 12.46 97.71 15.59 97.24 15.57 97.36 12.01 107.78 12.37 107.65 15.93 107.18 15.92"
          />
          <path
            fill={fill}
            d="M121,21.3a7.84,7.84,0,0,1,1.38-2.41,6.4,6.4,0,0,1,1.92-1.55,5.59,5.59,0,0,1,4.7-.23,5.8,5.8,0,0,1,2.1,1.31,5.59,5.59,0,0,1,1.3,2,6.84,6.84,0,0,1-.1,5.12,7,7,0,0,1-3.26,3.94,5.49,5.49,0,0,1-2.28.64,5.93,5.93,0,0,1-2.46-.39,5.6,5.6,0,0,1-3.41-3.28A7,7,0,0,1,121,21.3m9,4.68.86-2.31a5.66,5.66,0,0,0,.24-3.89,3.73,3.73,0,0,0-5.45-2,5.75,5.75,0,0,0-2.37,3.09l-.86,2.31a5.78,5.78,0,0,0-.25,3.93,3.81,3.81,0,0,0,5.46,2A5.77,5.77,0,0,0,130.05,26"
          />
        </g>
      </g>
    </svg>
  );
};

Logo.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  bgColor: PropTypes.string,
};

Logo.defaultProps = {
  size: 60,
  fill: "#ffcc82d",
  bgColor: "#ffc82f",
};

export default React.memo(Logo);
