import React, { useState, useEffect } from "react";
import Header from "./component/Header/container";
import { Toaster } from "react-hot-toast";
import { getUploadPdf,deletePDF } from "./services";
import "./style/index.css";
import { Icon, Button, Confirm } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import moment from "moment";
import Pdf from "./../../icon/Pdf";
import Timer from "./../../icon/Timer";
import toast from "react-hot-toast";

const Reports = (props) => {
  const getdae = new Date();
  const [getAllPDF, setAllPdf] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [setId, setpdfId] = useState('false');
  const [emptyErrorMessage,setEmptyErrorMessage] = useState('');
  
  useEffect(() => {
    getuploadPDFFile();
  }, []);

  const hideandShowPopup = (status = false,id='') => {
    setpdfId(id);
    setShowPopup(status);
  };

  const hidePopup = () => {
    setpdfId('');
    setShowPopup(false);
  };
  

  const getuploadPDFFile = async () => {
    try {
      const getpdf = await getUploadPdf();
      const { data: responseData = [] } = getpdf;
      const { message = "", success = false, data = [] } = responseData;
      setAllPdf(data);
      if(isEmpty(data)){
        setEmptyErrorMessage("No reports saved");
      }
    } catch (error) {
      console.log("something wrong");
    }
  };

  const handleConfirm =()=>{
    handleConfirmDelete();
    hideandShowPopup()
  }

  const handleConfirmDelete =  async()=>{
    if(!setId){
    return true;
    }
    try {
      const getpdf = await deletePDF(setId);
      const { data: responseData = [] } = getpdf;
      const { message = "", success = false, data = [] } = responseData;
      toast.success(message);
      getuploadPDFFile()
    } catch (error) {
      console.log("something wrong");
      toast.success("something wrong");
    }
   
  } 

  const downloadImage = (e, url = "") => {
    e.preventDefault();
    window.location.href = url;
  };

  const renderReport = () => {

    if (!isEmpty(getAllPDF)) {
      return getAllPDF.map((item) => {
        const {
          name = "",
          uuid = "",
          url = "",
          createdAt = "",
          expiryAt = "",
        } = item;
        const splitName = name.split(".");
        const nameTitle = get(splitName, "0", "");
        const key = uuid+name;
        return (
          <div className="report" key={key}>
            <div
              className="w40 ptb borderLeft name reportsItem"
              onClick={(e) => {
                downloadImage(e, url);
              }}
            >
              <div className="flex">
                <Pdf size={20} />
                {nameTitle}
              </div>
            </div>
            <div className="date ptb borderLeft reportsItem w20">
            <div className="flex justifyContent"> {moment(createdAt).format("ll")}</div>
            </div>
            {expiryAt && (
              <div className="date ptb reportsItem w30">
             
                <div className="flex justifyContent">
                  
                  <Timer size={20} />Link expires on {moment(expiryAt).add(1, 'day').format('LLL')}.
                </div>
              </div>
            )}
            <div className="ptb icon reportsItem w10">
              <Icon name="trash alternate outline f18" onClick={()=>{hideandShowPopup(true,uuid)}} />
            </div>
          
          </div>
            
        );
      });
    }
    return <div className='marginLeftNav'>{emptyErrorMessage}</div>
  };

  return (
    <div>
      <Header pageName ="My Reports"/>
      <div className="outerContainer">
        <div className="container">
          <div className="reports">{renderReport()}</div>
        </div>
      </div>
      { showPopup && <Confirm
      open={true} 
      size='mini'
      content="Are you sure you want to delete this PDF?"
              onCancel={hidePopup}
              onConfirm={handleConfirm}
            />}
      <Toaster />
    </div>
  );
};

export default Reports;
