export const SET_SEARCH_INSIGHT_FILTERS = "SET_SEARCH_INSIGHT_FILTERS";
export const SET_INSIGHT_GRAPH = "SET_INSIGHT_GRAPH";
export const SET_EMPTY_INSIGHT_GRAPH = "SET_EMPTY_INSIGHT_GRAPH";
export const SET_FILTER_TAB = "SET_FILTER_TAB";
export const SET_REACH_GRAPH = "SET_REACH_GRAPH";
export const SET_TOTLE_SESSION_GRAPH = "SET_TOTLE_SESSION_GRAPH";
export const SET_AVERAGE_SESSION_GRAPH = "SET_AVERAGE_SESSION_GRAPH";
export const SET_SESSION_OPEN_RATE = "SET_SESSION_OPEN_RATE";
export const SET_MONTHLY_ACTIVE_USERS = "SET_MONTHLY_ACTIVE_USERS";
export const SET_WEEKLI_ACTIVE_USERS = "SET_WEEKLI_ACTIVE_USERS";
export const SET_DAILY_ACTIVE_USERS = "SET_DAILY_ACTIVE_USERS";
export const SET_AVERAGE_REVENUE_PER_USER = "SET_AVERAGE_REVENUE_PER_USER";
export const SET_AVERAGE_ORDER_FREQUENCY = "SET_AVERAGE_ORDER_FREQUENCY";
export const SET_AVERAGE_ORDER_VALUE = "SET_AVERAGE_ORDER_VALUE";
export const SET_UNINSTALL_RATE = "SET_UNINSTALL_RATE";
export const SET_GROWTH_RATE = "SET_GROWTH_RATE";
export const SET_RECOMMENDATION = "SET_RECOMMENDATION";
export const SET_INSIGHT_TABLE_DATA = "SET_INSIGHT_TABLE_DATA";

// Set filters data to render the graph
export const setSearchInsightFilters = (filters = []) => ({
  type: SET_SEARCH_INSIGHT_FILTERS,
  data: filters,
});

export const setInsightGraph = (item = []) => ({
  type: SET_INSIGHT_GRAPH,
  data: item,
});

export const setEmptyInsightGraph = () => ({
  type: SET_EMPTY_INSIGHT_GRAPH,
});

export const setFilterTab = (name = "") => ({
  type: SET_FILTER_TAB,
  name,
});

export const setReachGraph = (item = {}) => ({
  type: SET_REACH_GRAPH,
  data: item,
});

export const setTotalSessiontimeGraph = (item = {}) => ({
  type: SET_TOTLE_SESSION_GRAPH,
  data: item,
});

export const setSessionOpenRateGraph = (item = {}) => ({
  type: SET_SESSION_OPEN_RATE,
  data: item,
});

export const setAverageSessionTimeGraph = (item = {}) => ({
  type: SET_AVERAGE_SESSION_GRAPH,
  data: item,
});

export const setWeeklyActiveUserGraph = (item = {}) => ({
  type: SET_WEEKLI_ACTIVE_USERS,
  data: item,
});

export const setMonthlyActiveUserGraph = (item = {}) => ({
  type: SET_MONTHLY_ACTIVE_USERS,
  data: item,
});

export const setDailyActiveUserGraph = (item = {}) => ({
  type: SET_DAILY_ACTIVE_USERS,
  data: item,
});

export const setAverageRevenuePerUserGraph = (item = {}) => ({
  type: SET_AVERAGE_REVENUE_PER_USER,
  data: item,
});

export const setAverageOrderFrequencyGraph = (item = {}) => ({
  type: SET_AVERAGE_ORDER_FREQUENCY,
  data: item,
});

export const setAverageOrderValueGraph = (item = {}) => ({
  type: SET_AVERAGE_ORDER_VALUE,
  data: item,
});

export const setUninstall = (item = {}) => ({
  type: SET_UNINSTALL_RATE,
  data: item,
});

export const setGrowthRate = (item = {}) => ({
  type: SET_GROWTH_RATE,
  data: item,
});

export const setRecommendation = (item = {}) => ({
  type: SET_RECOMMENDATION,
  data: item,
});

export const setInsightTableData = (data = []) => ({
  type: SET_INSIGHT_TABLE_DATA,
  data,
});