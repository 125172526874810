// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Input } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { Dropdown } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import "./style/index.css";

import {
  END_DATE,
  START_DATE,
  CUSTOM,
  MONTHLY_GROWTH_RATE_MIN,
  MONTHLY_GROWTH_RATE_MAX,
  MODEL,
  SECTOR
} from "../../../../constants";
import { filterDate } from "../../../../constants";

import Calendar from "../../../../icon/Calendar";
import { dateTimeHelper } from "../../../../helper";

const InsightsPlus = (props) => {
  const {
    formState,
    onClickFormData,
    isLoading,
    onClickSubmit,
    setInsightsPlusState,
    errorStateInsitePlus,
    emptryState,
    emptyDetailPage,
    setFilterTab,
    sectorList,
  } = props;

  const [dateTimeStatus, setDateTimeStatus] = useState(false);
  const [selectedSector, setSelectedSector] = useState([]);

  const {
    date: errorData = "",
    monthlyGrowthRateMin: errorMonthlyGrowthRateMin = "",
    monthlyGrowthRateMax: errorMonthlyGrowthRateMax = "",
  } = errorStateInsitePlus;

  useEffect(() => {
    emptyDetailPage();
    emptryState();
    setFilterTab(MODEL);
    
    const getDate = dateTimeHelper(6);
    const { stateDate, endDay } = getDate;

    const newForm = {
      ...formState,
      dateValue: 6,
      stateDate: stateDate,
      endDate: endDay,
      monthlyGrowthRateMin: "0",
      monthlyGrowthRateMax: "1",
      sector: "",
    };
    setInsightsPlusState(newForm);
  }, []);


  const handlerDateTime = (value) => {
    if (value === CUSTOM) {
      setDateTimeStatus(true);
      const newForm = {
        ...formState,
        dateValue: value,
      };
      setInsightsPlusState(newForm);
    } else {
      const getDate = dateTimeHelper(value);
      const { stateDate, endDay } = getDate;
      const newForm = {
        ...formState,
        dateValue: value,
        [END_DATE]: endDay,
        [START_DATE]: stateDate,
      };
      setInsightsPlusState(newForm);
      setDateTimeStatus(false);
    }
  };

  const ddSectorList = sectorList.map((item) => {
    const { display_name = "", select_name = "" } = item;
    return { text: display_name, value: select_name, key: select_name };
  });    

  const renderFilterDate = () => {
    return (
      <div>
        <div className="mt40 dateTime">
          <label className="lable">Date Range*</label>
          <Dropdown
            placeholder="DATE"
            fluid
            clearable
            className="w250 mb10"
            search
            selection
            value={formState.dateValue}
            onChange={(e, data) => handlerDateTime(data.value)}
            options={filterDate}
          />
          {dateTimeStatus && (
            <DateTime>
              <div className="positionRelative">
                <DatePicker
                  selected={formState.stateDate}
                  className="w120"
                  placeholderText="Start Date"
                  showMonthDropdown
                  showYearDropdown
                  icon={<Calendar size={20} />}
                  onChange={(data) => onClickFormData(data, START_DATE)}
                />
              </div>
              <div className="positionRelative">
                <DatePicker
                  className="w120 ml10"
                  placeholderText="End Date"
                  selected={formState.endDate}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(data) => onClickFormData(data, END_DATE)}
                />
              </div>
            </DateTime>
          )}
          {errorData && <div className="error">{errorData}</div>}
        </div>
      </div>
    );
  };

  const renderFilter = () => {
    return (
      <div className="mT25 filter">
          {/* monthly growth rate filter field */}
          <label className="lable mT15">Monthly Growth Rate *</label>
          <div className="flex">
            <div className="smallInput mrRight10">
              <label className="lable ">Min *</label>
              <Input
                onChange={(e, data) => {
                  onClickFormData(data.value, MONTHLY_GROWTH_RATE_MIN)
                }}
                type="number"
                value={formState.monthlyGrowthRateMin}
                placeholder="0.5"
              />
              
            </div>
            <div className="smallInput">
              <label className="lable ">Max</label>
              <Input
                onChange={(e, data) => {
                  onClickFormData(data.value, MONTHLY_GROWTH_RATE_MAX)
                }}
                type="number"
                value={formState.monthlyGrowthRateMax}
                placeholder="0.5"
              />
            </div>
          </div>
          {errorMonthlyGrowthRateMin && <div className="error">{errorMonthlyGrowthRateMin}</div>}
          {errorMonthlyGrowthRateMax && <div className="error">{errorMonthlyGrowthRateMax}</div>}

          {/* sector filter field */}                    
          <div className="mT15">
          <label className="lable">Select sector</label>
            <Dropdown
              placeholder="SECTOR*"
              fluid
              clearable
              className="w280"
              search
              multiple
              selectedLabel
              options={ddSectorList}
              selection={true}
              value={formState.sector || selectedSector}
              onChange={(e, data) => { 
                  onClickFormData(data.value, SECTOR)
                  setSelectedSector(e.target.value)
                }
              }
            />
          </div>

          <div className="mT15">
            <Button
              className="submitButton"
              disabled={!formState.dateValue}
              onClick={() => onClickSubmit('table-model')} loading={isLoading}>
              Submit
            </Button>
          </div>
      </div>
    );
  };

  const renderBrowse = () => {
    return (
      <React.Fragment>
        <div className="plr">
          {renderFilterDate()}
          {renderFilter()}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderBrowse()}
    </React.Fragment>
  );
};

InsightsPlus.propTypes = {
  formState: PropTypes.objectOf(PropTypes.any),
  updateFormState: PropTypes.func,
};

InsightsPlus.defaultProps = {
  formState: {},
  updateFormState: noop,
};

const DateTime = styled.div`
  display: flex;

  .react-datepicker-wrapper {
    width: 120px;
  }
  input {
    outline: 0;
    transform: rotateZ(0);
    min-width: 100px;
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: 0.78571429em 1em 0.78571429em 1em;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
  }
`;

export default InsightsPlus;
