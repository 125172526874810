// Libraries
import React from "react";
import styled from "styled-components";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Icon
import Logo from "../../../icon/Logo";

// constants
import { leftNav } from "../../../constants";
import { renderIcon } from "../../../helper";

const LeftNavigation = (props) => {
  const { isLogin = false, location } = props;
  const renderLeftMenu = () => {
    return (
      <StyledLeftMenu>
        <StyledUnOrderList>
          {leftNav.map((item) => {
            const { name = "", key = "", url = "" } = item;
            return (
              <StyledUnOrderListItem key={name}>
                <Link to={url} key={name}>
                  {renderIcon(key, url, location)}
                </Link>
              </StyledUnOrderListItem>
            );
          })}
        </StyledUnOrderList>
      </StyledLeftMenu>
    );
  };

  const renderLogo = () => {
    return (
      <StyledLogo>
        <Logo size={60} />
      </StyledLogo>
    );
  };

  return (
    isLogin && (
      <StyledLeftHeader>
        {renderLogo()}
        {renderLeftMenu()}
      </StyledLeftHeader>
    )
  );
};

const StyledLeftHeader = styled.div`
  background: #000;
  height: 100vh;
  width: 100px;
  position: fixed;
  left: 0;
`;

const Link = styled(NavLink)`
  font-weight: 500;
`;

const StyledUnOrderList = styled.ul`
  padding: 0;
  margin: 0;
`;

const StyledUnOrderListItem = styled.li`
  margin-bottom: 5rem;
`;
const StyledLeftMenu = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledLogo = styled.div`
  width: 100px;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
`;

const mapStateToProps = ({ bootstrapReducer }) => {
  const { user = {} } = bootstrapReducer;
  const { access_token = "" } = user;
  const isLogin = !!access_token;
  return { isLogin };
};

export default withRouter(connect(mapStateToProps)(LeftNavigation));

//export default withRouter(LeftNavigation);
