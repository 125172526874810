import React from "react";
import PropTypes from "prop-types";

const Calendar = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.07 27.1"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="iocn">
            <path
              fill={fill}
              d="M22.37,27.1a2.7,2.7,0,0,0,2.7-2.7V4.85a2.71,2.71,0,0,0-2.7-2.7H2.7A2.71,2.71,0,0,0,0,4.85V24.4a2.7,2.7,0,0,0,2.7,2.7ZM1.12,24.4V4.85A1.58,1.58,0,0,1,2.7,3.27H22.37A1.58,1.58,0,0,1,24,4.85V24.4A1.58,1.58,0,0,1,22.37,26H2.7A1.58,1.58,0,0,1,1.12,24.4Z"
            />
            <path
              fill={fill}
              d="M7.84,5V.56a.56.56,0,0,0-1.12,0V5A.56.56,0,0,0,7.84,5Z"
            />
            <path
              fill={fill}
              d="M18.47,5V.56a.56.56,0,1,0-1.12,0V5a.56.56,0,0,0,1.12,0Z"
            />
            <rect fill={fill} x="0.56" y="7.59" width="23.83" height="1.12" />
            <path
              fill={fill}
              d="M5.62,12.63A.66.66,0,0,0,5,12a.67.67,0,1,0,0,1.33A.66.66,0,0,0,5.62,12.63Z"
            />
            <path
              fill={fill}
              d="M10.66,12.63a.67.67,0,1,0-.67.66A.67.67,0,0,0,10.66,12.63Z"
            />
            <path
              fill={fill}
              d="M15.69,12.63A.67.67,0,0,0,15,12a.67.67,0,1,0,.67.67Z"
            />
            <path
              fill={fill}
              d="M20.72,12.63a.67.67,0,1,0-.67.66A.67.67,0,0,0,20.72,12.63Z"
            />
            <path
              fill={fill}
              d="M5.62,17.25A.66.66,0,0,0,5,16.58a.67.67,0,1,0,0,1.33A.66.66,0,0,0,5.62,17.25Z"
            />
            <path
              fill={fill}
              d="M10.66,17.25a.67.67,0,1,0-.67.66A.67.67,0,0,0,10.66,17.25Z"
            />
            <path
              fill={fill}
              d="M15.69,17.25a.67.67,0,0,0-.67-.67.67.67,0,1,0,.67.67Z"
            />
            <path
              fill={fill}
              d="M20.72,17.25a.67.67,0,1,0-.67.66A.67.67,0,0,0,20.72,17.25Z"
            />
            <path
              fill={fill}
              d="M5.62,21.87A.66.66,0,0,0,5,21.2a.67.67,0,1,0,0,1.33A.66.66,0,0,0,5.62,21.87Z"
            />
            <path
              fill={fill}
              d="M10.66,21.87a.67.67,0,1,0-.67.66A.67.67,0,0,0,10.66,21.87Z"
            />
            <path
              fill={fill}
              d="M15.69,21.87A.67.67,0,0,0,15,21.2a.67.67,0,1,0,.67.67Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

Calendar.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Calendar.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Calendar);
