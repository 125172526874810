import React from "react";
import PropTypes from "prop-types";

const Settings = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect
            fill={fill}
            x="14.55"
            y="4.5"
            width="9.9"
            height="2"
            transform="translate(1.82 15.4) rotate(-45)"
          />
          <path
            fill={fill}
            d="M11.79,14.85,5,21.66a1.18,1.18,0,0,1-1.64,0,1.16,1.16,0,0,1,0-1.64l6.77-6.77a5.48,5.48,0,0,0-1.84-1L1.93,18.6a3.15,3.15,0,0,0,0,4.47,3.14,3.14,0,0,0,4.46,0l6.82-6.81Z"
          />
          <path
            fill={fill}
            d="M21,24a3.34,3.34,0,0,1-2.39-.92L9.85,14.32A4.47,4.47,0,0,0,6.67,13H6.5a6.84,6.84,0,0,1-1.62-.2A6.48,6.48,0,0,1,.55,3.89l.32-.74a.47.47,0,0,1,.34-.27A.48.48,0,0,1,1.62,3L5.25,6.78a1.1,1.1,0,0,0,1.54,0,1.07,1.07,0,0,0,0-1.54L3,1.62a.48.48,0,0,1-.14-.41A.47.47,0,0,1,3.15.87L3.89.55A6.49,6.49,0,0,1,12.8,4.87,6.86,6.86,0,0,1,13,6.6a4.51,4.51,0,0,0,1.32,3.25l8.76,8.76A3.24,3.24,0,0,1,24,21a3.17,3.17,0,0,1-3,3ZM6.67,11a6.44,6.44,0,0,1,4.59,1.9L20,21.66A1.39,1.39,0,0,0,21,22a1,1,0,0,0,.67-.35A1,1,0,0,0,22,21a1.22,1.22,0,0,0-.34-1L12.9,11.26A6.51,6.51,0,0,1,11,6.57,4.48,4.48,0,0,0,7.53,2.11,4.73,4.73,0,0,0,6.29,2L8.17,3.81A3.07,3.07,0,0,1,9.11,6a3,3,0,0,1-.9,2.21,3.16,3.16,0,0,1-4.4,0L2,6.3a4.28,4.28,0,0,0,.11,1.23,4.46,4.46,0,0,0,3.25,3.33A4.79,4.79,0,0,0,6.5,11Z"
          />
        </g>
      </g>
    </svg>
  );
};

Settings.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Settings.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Settings);
