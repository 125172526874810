// Libraries
import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import DeleteModal from "../modalPopUp/DeleteModal";

import Star from "../../../icon/Star";

import "./style/index.css";

const CompanyList = (props) => {
  const { companyName = "", companies = [], onClick, selecedCompanies = [], handleRemoveCompany } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [favouritesRemoveId, setFavouritesRemoveId] = useState("");
  const hideandShowPopup = (status = false, id = "") => {
    setShowPopup(status);
    setFavouritesRemoveId(id);
  };

  useEffect(() => {
    if (showPopup) {
      setShowPopup(false);
      setFavouritesRemoveId("");
    }
  }, [companies]);

  const closePopup = () => {
    setShowPopup(false);
    setFavouritesRemoveId("");
  };

  const renderTitle = () => {
    return (
      <div>
        <h4>Showing apps for</h4>
        <p>(add up to 5 companies - click to select & deselect)</p>
      </div>
    );
  };

  const renderCompanies = () => {
    return (
      <div className="listPanal  overflowX showScrollbar">
        {!isEmpty(companies) &&
          companies.map((item, index) => {
            const { company = "", id='',isFavourite = false ,package:packageName=""} = item;
            // icon fill color flag
            const isSelectedItem = selecedCompanies.some((item) => item.package === packageName);
            const iconFill = isFavourite ? "#ffc82d" : "#999";
            const addclassname = isSelectedItem ? "listItem active" : "listItem";
            const key = `${packageName}_${index}`;
            return (
              <div key={key} className={addclassname}>
                <div
                  className="name"
                  onClick={() => {
                    onClick(item);
                  }}
                >
                  {company}
                </div>
                <span
                  onClick={() => {
                    hideandShowPopup(true, id);
                  }}
                >
                  <Star size={16} fill={iconFill} />
                </span>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="showcompanyList mt0 plr">
      {renderTitle()}
      {renderCompanies()}
      {showPopup && (
        <DeleteModal
          content="Are you sure you want to delete this company?"
          Title="Delete Company"
          close={showPopup}
          closePopup={closePopup}
          save={handleRemoveCompany(favouritesRemoveId)}
        />
      )}
    </div>
  );
};

export default CompanyList;
