import React from "react";
import { Button, Modal } from "semantic-ui-react";

const DeleteModal = (props) => {
  const { Title = "", content: contentText = "", closePopup, save } = props;
  return (
    <Modal size="mini" open onClose={closePopup}>
      <Modal.Header>{Title}</Modal.Header>
      <Modal.Content>
        <p>{contentText}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button className="submitButton" negative onClick={closePopup}>
          No
        </Button>
        <Button className="runReports" positive onClick={save}>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteModal;
