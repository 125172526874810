// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Input } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { Dropdown } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import "./style/index.css";
import CompanyList from "../../companyList";
import isEmpty from "lodash/isEmpty";

import {
  END_DATE,
  START_DATE,
  MODEL,
  CUSTOM,
  FIND,
  GROWTH_RATE,
  RECOMMENDATION,
  METRIC,
  PROBABILITY_RATE,
  RATE,
  START_GROWTH_RATE,
  END_GROWTH_RATE,
  SECTOR,
} from "../../../../constants";
import { filterTabInsightsPlus, filterDate, GrowthRate, matric } from "../../../../constants";

import Calendar from "../../../../icon/Calendar";
import { dateTimeHelper } from "../../../../helper";

const InsightsPlus = (props) => {
  const {
    sectorList,
    formState,
    onClickFormData,
    isLoading,
    onClickSubmit,
    setInsightsPlusState,
    setEmptyInsightGraph,
    setFilterTab,
    setSearchInsightFilters,
    errorStateInsitePlus,
    emptryState,
    companyList,
    updateCompaniesList,
    emptyDetailPage,
    setSectorList,
    addFavouriteCompanyName,
    removeCompanyList,
    handlerRunReportsInsightsPlus,
    isLoadingRunReport,
    companyColorMapping,
  } = props;

  const [tab, setTab] = useState([]);
  const [selecedTab, setSelecedTab] = useState("");
  const [dateTimeStatus, setDateTimeStatus] = useState(false);
  const [getAllSector, setGetAllSector] = useState(sectorList);

  useEffect(() => {
    setGetAllSector(sectorList);
  }, [sectorList]);

  const {
    date: errorData = "",
    probabilityRate: errorProbabilityRate = "",
    rate: errorRate = "",
    recommendation: errorRecommendation = "",
    metric: errorMetric = "",
    growth_rate: errorGrowthrate = "",
  } = errorStateInsitePlus;

  const renderDDSectorList =
    getAllSector &&
    Object.keys(getAllSector).map((sectreName) => {
      return {
        key: sectreName,
        text: sectreName,
        value: sectreName,
      };
    });

  useEffect(() => {
    emptyDetailPage();
    setTab(filterTabInsightsPlus);
    setSelecedTab(FIND);
    setFilterTab(FIND);
    emptryState();
    removeCompanyList([]);
    const getDate = dateTimeHelper(6);
    const { stateDate, endDay,dateValue } = getDate;
    const newForm = {
      ...formState,
      dateValue: 6,
      stateDate: stateDate,
      endDate: endDay,
      sector: [],
      recommendation: "",
      growthRate: "",
      metric: "",
      probabilityRate: "0.1",
      companies: [],
      growth_rate: "",
    };
    setSectorList();
    setEmptyInsightGraph();
    setInsightsPlusState(newForm);
  }, []);

  const renderTab = () => {
    return (
      <StyledUnOrderList className="filterTab">
        {tab.map((item) => {
          const { name = "", key = "" } = item;
          const active = selecedTab === key;
          return (
            <StyledUnOrderListItem
              onClick={() => {
                handlerTab(key);
              }}
              active={active}
              key={key}
            >
              {name}
            </StyledUnOrderListItem>
          );
        })}
      </StyledUnOrderList>
    );
  };

  const handlerTab = (value = "") => {
    setSelecedTab(value);
    emptyDetailPage();
    setFilterTab(value);
    emptryState();
    removeCompanyList();
    setSectorList();
 //   const getDate = dateTimeHelper(6);
  //  const { stateDate, endDay } = getDate;
    const newForm = {
      ...formState,
    //  dateValue: 6,
     // stateDate: stateDate,
     // endDate: endDay,
      sector: [],
      recommendation: "",
      growthRate: "",
      metric: "",
      probabilityRate: "0.1",
      companies: [],
      growth_rate: "",
    };
    setEmptyInsightGraph();
    setInsightsPlusState(newForm);
    setFilterTab(value);
    setSearchInsightFilters();
  };

  const handlerDateTime = (value) => {
    if (value === CUSTOM) {
      setDateTimeStatus(true);
      const newForm = {
        ...formState,
        dateValue: value,
      };
      setInsightsPlusState(newForm);
    } else {
      const getDate = dateTimeHelper(value);
      const { stateDate, endDay } = getDate;
      const newForm = {
        ...formState,
        dateValue: value,
        [END_DATE]: endDay,
        [START_DATE]: stateDate,
      };
      setInsightsPlusState(newForm);
      setDateTimeStatus(false);
    }
  };

  const renderFilterDate = () => {
    return (
      <div>
        <div className="mt40 dateTime">
          <label className="lable">Date Range*</label>
          <Dropdown
            placeholder="DATE"
            fluid
            clearable
            className="w250 mb10"
            search
            selection
            value={formState.dateValue}
            onChange={(e, data) => handlerDateTime(data.value)}
            options={filterDate}
          />
          {dateTimeStatus && (
            <DateTime>
              <div className="positionRelative">
                <DatePicker
                  selected={formState.stateDate}
                  className="w120"
                  placeholderText="Start Date"
                  icon={<Calendar size={20} />}
                  onChange={(data) => onClickFormData(data, START_DATE)}
                />
              </div>
              <div className="positionRelative">
                <DatePicker
                  className="w120 ml10"
                  placeholderText="End Date"
                  selected={formState.endDate}
                  onChange={(data) => onClickFormData(data, END_DATE)}
                />
              </div>
            </DateTime>
          )}
          {errorData && <div className="error">{errorData}</div>}
        </div>
      </div>
    );
  };

  const renderFilter = () => {
    return (
      <div className="mT25 filter">
        {selecedTab === FIND && (
          <React.Fragment>
            <label className="lable">BY GROWTH TYPE*</label>
            <div>
              <Dropdown
                placeholder="GROWTH RATE"
                fluid
                clearable
                search
                selection
                className="w220"
                value={formState.recommendation}
                options={GrowthRate}
                onChange={(e, data) => onClickFormData(data.value, RECOMMENDATION)}
              />
              {errorRecommendation && <div className="error">{errorRecommendation}</div>}
            </div>
            {formState.recommendation === GROWTH_RATE && (
              <React.Fragment>
                <div className="mT15 smallInput growthrate">
                  <Input
                    type="number"
                    onChange={(e, data) => onClickFormData(data.value, START_GROWTH_RATE)}
                    labelPosition='right'
                    label={{ basic: true, content: '%' }}
                    value={formState.growth_rate}
                    placeholder="10"
                  />
                  {errorGrowthrate && <div className="error">{errorGrowthrate}</div>}
                </div>
             
                <div className="mT15">
                  <Dropdown
                    placeholder="METRICS*"
                    fluid
                    clearable
                    search
                    selection
                    className="w220"
                    value={formState.matric}
                    options={matric}
                    onChange={(e, data) => onClickFormData(data.value, METRIC)}
                  />
                  {errorMetric && <div className="error">{errorMetric}</div>}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {selecedTab === MODEL && (
          <React.Fragment>
            <div className="mT15 smallInput">
              <label className="lable ">Investment Probability Range (0-1) *</label>
              <Input

                onChange={(e, data) => onClickFormData(data.value, PROBABILITY_RATE)}
                type="number"
                value={formState.probabilityRate}
               
                placeholder="0.5"
              />
              {errorProbabilityRate && <div className="error">{errorProbabilityRate}</div>}
            </div>
          </React.Fragment>
        )}
        <div className="mT15">
          <Button className="submitButton" disabled={!formState.dateValue} onClick={onClickSubmit} loading={isLoading}>
            Submit
          </Button>
        </div>
        {!isEmpty(renderDDSectorList) && (
                <div className="mT25 filter">
                <label className="lable">Filter by</label>
          <div className="">
            <Dropdown
              placeholder="SECTOR*"
              fluid
              clearable
              className="w280"
              search
              options={renderDDSectorList}
              selection
              value={formState.sector}
              onChange={(e, data) => onClickFormData(data.value, SECTOR)}
            />
          </div>  </div>
        )}
      </div>
    );
  };

  const renderBrowse = () => {
    return (
      <React.Fragment>
        <div className="plr">
          {renderFilterDate()}
          {renderFilter()}
          {!isEmpty(companyList) && (
            <React.Fragment>
              <CompanyList
                companyName={formState.sector}
                companies={companyList}
                onClick={updateCompaniesList}
                selecedCompanies={formState.companies}
                addFavouriteCompanyName={addFavouriteCompanyName}
                showFavouritestab={false}
              />
              <div className="mT15">
                <Button
                  className={isEmpty(formState.companies) ? "submitButton" : "runReports"}
                  disabled={isEmpty(formState.companies)}
                  onClick={() => {
                    handlerRunReportsInsightsPlus(formState, companyColorMapping);
                  }}
                  loading={isLoadingRunReport}
                >
                  {selecedTab === MODEL ? "RUN REPORTS" : "RUN REPORTS"}
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="plr">{renderTab()}</div>
      {renderBrowse()}
    </React.Fragment>
  );
};

InsightsPlus.propTypes = {
  formState: PropTypes.objectOf(PropTypes.any),
  updateFormState: PropTypes.func,
  sectorList: PropTypes.arrayOf(PropTypes.any),
  companies: PropTypes.arrayOf(PropTypes.any),
  handlerCompany: PropTypes.func,
  updateCompanies: PropTypes.func,
  handlerRunReports: PropTypes.func,
};

InsightsPlus.defaultProps = {
  sectorList: [],
  formState: {},
  updateFormState: noop,
  handlerCompany: noop,
  companies: [],
  updateCompanies: noop,
  handlerRunReports: noop,
};

const DateTime = styled.div`
  display: flex;

  .react-datepicker-wrapper {
    width: 120px;
  }
  input {
    outline: 0;
    transform: rotateZ(0);
    min-width: 100px;
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: 0.78571429em 1em 0.78571429em 1em;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
  }
`;

const StyledUnOrderList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const StyledUnOrderListItem = styled.li`
  cursor: pointer;
  color: #fff;
  position: relative;
  font-weight: 600;
  padding: 10px 35px 8px 35px;
  background-color: ${(props) => (props.active ? "#f5f6f8" : "#718486")};
  color: ${(props) => (props.active ? "#718486" : "#f5f6f8")};
  text-transform: uppercase;
  display: inline-block;
  border: ${(props) => (props.active ? " 2px solid #718486" : "")};
  border-bottom: none;
`;

export default InsightsPlus;
