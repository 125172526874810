import axios from "../../../interceptors";
import {
  SECTOR_LIST,
  COMPANY_LIST,
  COMPANY_SEARCH,
  REACH,
  SESSION,
  MONTHLY_ACTIVE_USERS,
  COMPANY_WEEKLY_ACTIVE_USERS,
  DAILY_ACTIVE_USERS,
  COMPANY_AVERAGE_REVENUE_PER_USER,
  COMPANY_AVERAGE_ORDER_VALUE,
  UNINSTALL_RATE,
  COMPANY_AVERAGE_ORDER_FREQUENCY,
  Add_REMOVE_UPDATE_FAVOURITE_REPORT,
  FAVOURITE_REPORT_LIST,
  Add_REMOVE_FAVOURITE_COMPANY,
  FAVOURITE_COMPANY_LIST,
  UPLOAD_PDF,
} from "../../../constants/router";

export const getSectorList = () => axios.get(SECTOR_LIST);

export const getCompanyList = (name = "") => {
  return axios.get(COMPANY_LIST, {
    params: {
      sector: name,
    },
  });
};

export const getCompanySearch = (name = "") => {
  return axios.get(COMPANY_SEARCH, {
    params: {
      q: name,
      size: "30",
      suggester: "company_search",
    },
  });
};

export const getReach = (pageLoad) => {
  return axios.get(REACH, {
    params: {
      query: pageLoad,
    },
  });
};

export const getSession = (pageLoad) => {
  return axios.get(SESSION, {
    params: {
      query: pageLoad,
    },
  });
};

export const getMonthlActiveUser = (pageLoad) => {
  return axios.get(MONTHLY_ACTIVE_USERS, {
    params: {
      query: pageLoad,
    },
  });
};

export const getCompanyWeeklyActiveUser = (pageLoad) => {
  return axios.get(COMPANY_WEEKLY_ACTIVE_USERS, {
    params: {
      query: pageLoad,
    },
  });
};

export const getDailyActiveUsers = (pageLoad) => {
  return axios.get(DAILY_ACTIVE_USERS, {
    params: {
      query: pageLoad,
    },
  });
};

export const getCompanyAverageRevenuePerUser = (pageLoad) => {
  return axios.get(COMPANY_AVERAGE_REVENUE_PER_USER, {
    params: {
      query: pageLoad,
    },
  });
};

export const getCompanyAverageOrderValue = (pageLoad) => {
  return axios.get(COMPANY_AVERAGE_ORDER_VALUE, {
    params: {
      query: pageLoad,
    },
  });
};

export const getUninstallRate = (pageLoad) => {
  return axios.get(UNINSTALL_RATE, {
    params: {
      query: pageLoad,
    },
  });
};

export const getAverageOrderFrequency = (pageLoad) => {
  return axios.get(COMPANY_AVERAGE_ORDER_FREQUENCY, {
    params: {
      query: pageLoad,
    },
  });
};

export const getGrowthRecommendation = (pageLoad, url = "") => {
  return axios.get(url, {
    params: pageLoad,
  });
};

export const addRemoveFavourite = (body = {}, id = "") => {
  if (id) {
    return axios.delete(`${Add_REMOVE_UPDATE_FAVOURITE_REPORT}/${id}`);
  } else {
    return axios.post(Add_REMOVE_UPDATE_FAVOURITE_REPORT, body);
  }
};

export const getFavourite = () => {
  return axios.get(FAVOURITE_REPORT_LIST);
};

export const getFavouriteCompany = () => {
  return axios.get(FAVOURITE_COMPANY_LIST);
};

export const addFavouriteCompany = (name) => {
  return axios.post(Add_REMOVE_FAVOURITE_COMPANY, { name });
};

export const removeFavouriteCompany = (id) => {
  return axios.delete(`${Add_REMOVE_FAVOURITE_COMPANY}/${id}`);
};

export const pdfFileUpload = (file, name) => {
  const formData = new FormData();
  formData.append("report", file, `${name}.pdf`);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(UPLOAD_PDF, formData, config);
};
