const BASE_URL = "https://investor-api.kalagato.co/api/v1/";

// const BASE_URL = "http://localhost:8080/api/v1/";

const INVESTOR_RESEARCH = "investor-research/";

//  filters
export const SECTOR_LIST = `${BASE_URL}${INVESTOR_RESEARCH}sector-list`;
export const COMPANY_LIST = `${BASE_URL}${INVESTOR_RESEARCH}company-list`;
export const COMPANY_SEARCH = `${BASE_URL}${INVESTOR_RESEARCH}company-search`;

//  filters custom metrics
export const REACH = `${BASE_URL}${INVESTOR_RESEARCH}reach`;
export const SESSION = `${BASE_URL}${INVESTOR_RESEARCH}session`;
export const MONTHLY_ACTIVE_USERS = `${BASE_URL}${INVESTOR_RESEARCH}mau`;
export const COMPANY_WEEKLY_ACTIVE_USERS = `${BASE_URL}${INVESTOR_RESEARCH}wau`;
export const DAILY_ACTIVE_USERS = `${BASE_URL}${INVESTOR_RESEARCH}dau`;
export const COMPANY_AVERAGE_REVENUE_PER_USER = `${BASE_URL}${INVESTOR_RESEARCH}arpu`;
export const COMPANY_AVERAGE_ORDER_VALUE = `${BASE_URL}${INVESTOR_RESEARCH}aov`;
export const COMPANY_AVERAGE_ORDER_FREQUENCY = `${BASE_URL}${INVESTOR_RESEARCH}aof`;
export const UNINSTALL_RATE = `${BASE_URL}${INVESTOR_RESEARCH}uninstall-rate`;
export const GROWTH = `${BASE_URL}${INVESTOR_RESEARCH}growth`;
export const RECOMMENDATION = `${BASE_URL}${INVESTOR_RESEARCH}recommendation`;

// model
export const RUN_MODEL = `${BASE_URL}${INVESTOR_RESEARCH}run-model`;
export const FUNDING_PROBABILITY = `${BASE_URL}${INVESTOR_RESEARCH}funding-probability`;
export const FUNDING_INFO = `${BASE_URL}${INVESTOR_RESEARCH}funding-info`;

// user
export const LOGIN = `${BASE_URL}user/login`;
export const UPLOAD_PDF = `${BASE_URL}save/upload-pdf`;
export const GET_UPLOAD_PDF = `${BASE_URL}save/reports`;
export const DELETE_UPLOAD_PDF = `${BASE_URL}save/report`;

// Favourites
export const Add_REMOVE_UPDATE_FAVOURITE_REPORT = `${BASE_URL}favourite/user-favourite-report`;
export const FAVOURITE_REPORT_LIST = `${BASE_URL}favourite/user-favourite-reports`;

// company
export const Add_REMOVE_FAVOURITE_COMPANY = `${BASE_URL}favourite/user-favourite-company`;
export const FAVOURITE_COMPANY_LIST = `${BASE_URL}favourite/user-favourite-companies`;

// insights table

export const INSIGHTS_TABLE = `${BASE_URL}${INVESTOR_RESEARCH}listing`;
export const SEND_EMAIL = `${BASE_URL}${INVESTOR_RESEARCH}send-email`;
