import React, { useState } from "react";
import styled from "styled-components";

import Insight from "../../../../../icon/Insight";
import Mobile from "../../../../../icon/Mobile";
import { get, isEmpty } from "lodash";
import { Button, Popup } from "semantic-ui-react";
import DeleteModal from "../../../../common/modalPopUp/DeleteModal";

import { INSIGHT, SEARCH, colorChartMapping } from "../../../../../constants";
import { dateTimeHelper } from "../../../../../helper";

const FiltersSummery = (props) => {
  const {
    searchInsightFilters = {},
    favouritesSearchDeleta,
    title = "",
    updateForm,
    handlerHeaderTab,
    setPreFillTab,
    setCompanyColorMapping,
  } = props;
  const [showdropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const { sector = [], age, tier, gender, companies } = searchInsightFilters;
  const hideandShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const deleteItem = () => {
    favouritesSearchDeleta()
    setShowPopup(false)
  };

  const companyColorMapping =
    !isEmpty(companies) &&
    companies.map((item, index) => {
      return {
        name: item,
        color: colorChartMapping[index],
      };
    });

  const sendToinsights = () => {
    setPreFillTab(SEARCH);
    const getDate = dateTimeHelper(6);
    const { stateDate, endDay } = getDate;
    const newForm = {
      dateValue: 6,
      stateDate: stateDate,
      endDate: endDay,
      tier,
      age,
      gender,
      companies: companies,
    };
    updateForm(newForm);
    handlerHeaderTab(INSIGHT)();
    setCompanyColorMapping(companyColorMapping);
  };

  const renderAgeFilter = (filterData = {}) => {
    if (isEmpty(age)) {
      return null;
    }
    return age.map((ageGroup, index) => {
      return (
        <StyledAgeFilter key={`${index}_age`} className="flex">
          <div className="mrRight10">{`${ageGroup} YRS`}</div>
        </StyledAgeFilter>
      );
    });
  };

  const renderSecter = (filterData = {}) => {
    let { sector: secterItem = [] } = filterData;
    if (typeof secterItem === "string") {
      secterItem = [`${secterItem}`];
    }
    return secterItem.map((sectorGroup, index) => {
      return (
        <StyledAgeFilter key={`${index}_age`} className="flex">
          <div className="mrRight10">{`${sectorGroup}`}</div>
        </StyledAgeFilter>
      );
    });
  };

  const renderTier = (filterData = {}) => {
    if (isEmpty(tier)) {
      return null;
    }
    return tier.map((tierGroup, index) => {
      return (
        <StyledAgeFilter key={`${index}_age`} className="flex">
          <div className="mrRight10">{`${tierGroup}`}</div>
        </StyledAgeFilter>
      );
    });
  };

  const renderGenderFilter = (filterData = {}) => {
    if (isEmpty(gender)) {
      return null;
    }
    return gender.map((genderGroup, index) => {
      return (
        <StyledAgeFilter key={`${index}_gender`} className="flex">
          <div className="mrRight10">{genderGroup}</div>
        </StyledAgeFilter>
      );
    });
  };

  const renderCompaniesilter = (filterData = {}) => {
    if (isEmpty(companies)) {
      return null;
    }
    return (
      <div className="flex">
        <IconDiv>
          <Mobile size={18} />
        </IconDiv>
        <div>
          {companies.map((companyItem, index) => {
               const { company = ""} = companyItem;
            return (
              <StyledCompanyFilter key={`${index}_companry`} className="flex">
                <div className="mrRight10">{company}</div>
              </StyledCompanyFilter>
            );
          })}
        </div>
      </div>
    );
  };

  const renderAddDropDownTrigger = () => {
    return (
      <span className="dropdownTrigger">
        <Button icon="ellipsis horizontal" labelPosition="right" />
      </span>
    );
  };

  const renderPopupContent = (itemID) => {
    return (
      <div className="popcontent">
        <ul>
          <li onClick={hideandShowPopup}>Delete</li>
          <li>Share</li>
        </ul>
      </div>
    );
  };

  const onclickDropdown = () => {
    setShowDropdown(!showdropdown);
  };

  const renderPopup = () => {
    return (
      <Popup
        hoverable
        open={showdropdown}
        onClose={onclickDropdown}
        on="click"
        position="bottom right"
        onOpen={onclickDropdown}
        content={renderPopupContent()}
        trigger={renderAddDropDownTrigger()}
      />
    );
  };

  return (
    <div className="showfilterBox flex">
      <div className=" w70 ">
          <div className="filterTitleInfo" onClick={sendToinsights}>
            {title}
          </div>
        {(!isEmpty(age) || !isEmpty(sector) || !isEmpty(tier) || !isEmpty(gender)) && (
          <div className="flex">
            <IconDiv>
              <Insight size={18} />
            </IconDiv>
            {renderAgeFilter(searchInsightFilters)}
            {renderGenderFilter(searchInsightFilters)}
            {renderSecter(searchInsightFilters)}
            {renderTier(searchInsightFilters)}
          </div>
        )}
        {renderCompaniesilter(searchInsightFilters)}
      </div>
      <div className="flex  flexEnd w30">
        <div className="showDateTitel">{renderPopup()}</div>
      </div>
      {showPopup && (
        <DeleteModal
          content="Are you sure you want to delete this filter?"
          Title="Delete Filter"
          close={showPopup}
          closePopup={hideandShowPopup}
          save={deleteItem}
        />
      )}
    </div>
  );
};

const StyledAgeFilter = styled.div`
  padding: 2px 2px 2px 8px;
  font-size: 12px;
  width: fit-content;
  color: #718486;
  border-radius: 4px;
  border: 1px solid grey;
  margin: 0px 10px 20px 0px;
  display: inline-block;
  text-transform: uppercase;
`;

const StyledCompanyFilter = styled.div`
  padding: 2px 2px 2px 8px;
  font-size: 12px;
  width: fit-content;
  color: #718486;
  border-radius: 4px;
  margin: 0px 10px 10px 0px;
  background: #efefef;
  display: inline-block;
  text-transform: uppercase;
`;

const IconDiv = styled.div`
  margin-top: 6px;
  margin-right: 10px;
`;

// const StyledClose = styled.div`
//   font-size: 20px;
//   cursor: pointer;
//   display: none;
// `;
export default FiltersSummery;
