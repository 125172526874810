import React from "react";
import PropTypes from "prop-types";

const Save = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
     
      viewBox="0 0 14.82 14.82"
    >
  <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill={fill} d="M14.06,2.62H8L5.56.22A.8.8,0,0,0,5,0H.77A.76.76,0,0,0,0,.77V14.05a.76.76,0,0,0,.77.77H14.06a.76.76,0,0,0,.76-.77V3.39A.76.76,0,0,0,14.06,2.62ZM4.69,1.54,5.77,2.62H1.54V1.54ZM1.54,13.29V4.15H13.29v9.14Z"/></g></g>
    </svg>
  );
};



Save.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Save.defaultProps = {
  size: 12,
  fill: "#999",
};

export default React.memo(Save);
