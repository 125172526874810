import React from "react";
import PropTypes from "prop-types";

const Insight = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={fill}
            d="M22.93,17.28l-1.86-1.87a11,11,0,1,0-5.66,5.66l1.87,1.86A3.63,3.63,0,0,0,19.9,24a4.13,4.13,0,0,0,2.89-1.21A3.9,3.9,0,0,0,22.93,17.28ZM11,20a9,9,0,1,1,9-9A9,9,0,0,1,11,20Zm10.37,1.37a1.9,1.9,0,0,1-2.68.15l-1.46-1.46a11.53,11.53,0,0,0,2.83-2.83l1.46,1.46A1.9,1.9,0,0,1,21.37,21.37Z"
          />
          <rect fill={fill} x="5.5" y="9.84" width="2" height="2" />
          <rect fill={fill} x="13.5" y="9.84" width="2" height="2" />
          <rect fill={fill} x="9.5" y="9.84" width="2" height="2" />
        </g>
      </g>
    </svg>
  );
};

Insight.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Insight.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Insight);
