import React from "react";
import PropTypes from "prop-types";

const Favourites = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={fill}
            d="M28.2,3.8A13,13,0,0,0,8,20L.79,27.2a2.79,2.79,0,0,0,.06,3.95,2.85,2.85,0,0,0,2,.85h0a2.69,2.69,0,0,0,1.92-.79L12,24A13,13,0,0,0,28.2,3.8ZM3.38,29.8a.69.69,0,0,1-.5.2.87.87,0,0,1-.62-.26.82.82,0,0,1-.06-1.13l4.5-4.5L7.89,25.3ZM9.3,23.88,8.12,22.7l1.12-1.12c.19.22.38.42.57.61h0c.19.19.4.38.61.57Zm17.48-3.1A11,11,0,0,1,13,22.19l-.44-.32-.66-.51-.64-.58c-.2-.21-.39-.43-.58-.64l-.51-.66L9.81,19a11,11,0,1,1,17,1.74Z"
          />
          <path
            fill={fill}
            d="M27,10.68A1,1,0,0,0,26,10H21.72L20,4.68a1,1,0,0,0-1.9,0L16.28,10H12a1,1,0,0,0-.6,1.8l3.42,2.57-1.77,5.31a1,1,0,0,0,.33,1.11,1,1,0,0,0,1.15.06L19,18.07l4.47,2.78a1,1,0,0,0,1.15-.06A1,1,0,0,0,25,19.68l-1.77-5.31L26.6,11.8A1,1,0,0,0,27,10.68Zm-5.9,3.64,1.12,3.37L19.53,16a1,1,0,0,0-1.06,0l-2.64,1.65L17,14.32a1,1,0,0,0-.35-1.12L15,12h2a1,1,0,0,0,.95-.68L19,8.16l1.05,3.16A1,1,0,0,0,21,12h2l-1.6,1.2A1,1,0,0,0,21.05,14.32Z"
          />
        </g>
      </g>
    </svg>
  );
};

Favourites.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Favourites.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Favourites);
