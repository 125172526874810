import { GROWTH_RATE, FIND, MODEL } from "../../../constants/index";
import { GROWTH, INSIGHTS_TABLE, RECOMMENDATION, RUN_MODEL } from "../../../constants/router";
import moment from "moment";
import { createSingalObjectDataset } from "../helper";

export const renderServerData = (data = {}, filterTab = "", type = "") => {
  const {
    stateDate = "",
    endDate = "",
    recommendation = "",
    metric = "",
    probabilityRate = "",
    monthlyGrowthRateMin = "",
    monthlyGrowthRateMax = "",
    growth_rate = "",
    sector = "",
  } = data;

  let serverData = Object.create({});
  let apiUrl = "";

  const sDate = moment(stateDate).format("YYYY-MM-DD");
  const eDate = moment(endDate).format("YYYY-MM-DD");

  if (filterTab === FIND) {
    if (recommendation === GROWTH_RATE) {
      apiUrl = GROWTH;
      serverData.start_date = sDate;
      serverData.end_date = eDate;
      serverData.growth_rate = growth_rate*100;
      serverData.metric = metric;
    } else {
      apiUrl = RECOMMENDATION;
      serverData.start_date = sDate;
      serverData.end_date = eDate;
      serverData.recommendation = recommendation;
    }
    return { serverData, apiUrl };
  }

  if (filterTab === MODEL) {
    apiUrl = type === 'table-model' ? INSIGHTS_TABLE: RUN_MODEL;
    serverData.start_date = sDate;
    serverData.end_date = eDate;
    serverData.probability_rate = probabilityRate;
    serverData.monthly_growth_rate_from = monthlyGrowthRateMin;
    serverData.monthly_growth_rate_to = monthlyGrowthRateMax;
    serverData.sector = sector.length > 0 ? sector.join(",") : 'ALL';
    return { serverData, apiUrl };
  }
  return { serverData: {}, apiUrl: "" };
};

const keyMapping = (key) => {
  switch (key) {
    case "CompanyMonthlyActiveUsers":
      return {
        pageTitle: "Monthly Active Users",
        keyMonthName: "monthly_active_users_extended__date_label_month",
        keyGetItem: "monthly_active_users_extended__monthly_active_users",
      };
    case "CompanyWeeklyActiveUsers":
      return {
        pageTitle: "Company Weekly Active Users",
        keyMonthName: "weekly_active_users_extended__date_label_month",
        keyGetItem: "weekly_active_users_extended__weekly_active_users",
      };
    case "CompanyDailyActiveUsers":
      return {
        pageTitle: "Daily Active Users",
        keyMonthName: "daily_active_users_extended__date_label_month",
        keyGetItem: "daily_active_users_extended__daily_active_users",
      };
    case "CompanyReachMonthly":
      return {
        pageTitle: "Reach",
        keyMonthName: "reach_monthly_extended__date_label_monthly",
        keyGetItem: "reach_monthly_extended__reach",
      };
    case "CompanyUninstallRate":
      return {
        keyMonthName: "uninstall_rate_extended__date_label_month",
        keyGetItem: "uninstall_rate_extended__uninstall_rate",
        pageTitle: "Uninstall Rate",
      };
    case "CompanyAverageOrderValue":
      return {
        keyMonthName: "monthly_extended__date_label_month",
        pageTitle: "Average Per Order",
        keyGetItem: "monthly_extended__aov",
      };
    case "CompanyAverageOrderFrequency":
      return {
        keyMonthName: "monthly_extended__date_label_month",
        pageTitle: "Average Order Frequency",
        keyGetItem: "monthly_extended__aof",
      };
    case "CompanyAverageRevenuePerUser":
      return {
        pageTitle: "Company Average Revenue Per User",
        keyMonthName: "monthly_extended__date_label_month",
        keyGetItem: "monthly_extended__arpu",
      };

    default:
      return;
  }
};

export const createInsightsPlusDataset = (data = {}, formstate = {}) => {
  const objectType = true;
  const dateSet = [];
  Object.keys(data).forEach((childItem) => {
    const currentItem = data[childItem] || [];
    switch (childItem) {
      case "CompanySessionMonthly":
        const pageKey1 = {
          keyMonthName: "session_monthly_extended__date_label_month",
          keyGetItem: "session_monthly_extended__opens",
          pageTitle: "Monthly Session Time",
        };
        const pageKey2 = {
          keyMonthName: "session_monthly_extended__date_label_month",
          keyGetItem: "session_monthly_extended__avg_session_time",
          pageTitle: "Avg Session Time",
        };
        const pageKey3 = {
          keyMonthName: "session_monthly_extended__date_label_month",
          keyGetItem: "session_monthly_extended__total_session_time",
          pageTitle: "Total Session Time",
        };
        const item = createSingalObjectDataset(currentItem, formstate, pageKey1, objectType);
        const item1 = createSingalObjectDataset(currentItem, formstate, pageKey2, objectType);
        const item2 = createSingalObjectDataset(currentItem, formstate, pageKey3, objectType);
        dateSet.push(item);
        dateSet.push(item1);
        dateSet.push(item2);
        break;
      case "CompanyMonthlyActiveUsers":
        const monthlyActiveUsersKey = keyMapping("CompanyMonthlyActiveUsers");
        const monthlyActiveUsers = createSingalObjectDataset(currentItem, formstate, monthlyActiveUsersKey, objectType);
        dateSet.push(monthlyActiveUsers);
        break;
      case "CompanyWeeklyActiveUsers":
        const CompanyWeeklyActiveUsersKey = keyMapping("CompanyWeeklyActiveUsers");
        const CompanyWeeklyActiveUsers = createSingalObjectDataset(
          currentItem,
          formstate,
          CompanyWeeklyActiveUsersKey,
          objectType
        );
        dateSet.push(CompanyWeeklyActiveUsers);
        break;
      case "CompanyDailyActiveUsers":
        const CompanyDailyActiveUsersKey = keyMapping("CompanyDailyActiveUsers");
        const CompanyDailyActiveUsers = createSingalObjectDataset(
          currentItem,
          formstate,
          CompanyDailyActiveUsersKey,
          objectType
        );
        dateSet.push(CompanyDailyActiveUsers);
        break;
      case "CompanyReachMonthly":
        const CompanyReachMonthlyKey = keyMapping("CompanyReachMonthly");
        const CompanyReachMonthly = createSingalObjectDataset(
          currentItem,
          formstate,
          CompanyReachMonthlyKey,
          objectType
        );
        dateSet.push(CompanyReachMonthly);
        break;
      case "CompanyUninstallRate":
        const CompanyUninstallRateKey = keyMapping("CompanyUninstallRate");
        const CompanyUninstallRate = createSingalObjectDataset(
          currentItem,
          formstate,
          CompanyUninstallRateKey,
          objectType
        );
        dateSet.push(CompanyUninstallRate);
        break;
      case "CompanyAverageOrderValue":
        const CompanyAverageOrderValueKey = keyMapping("CompanyAverageOrderValue");
        const CompanyAverageOrderValue = createSingalObjectDataset(
          currentItem,
          formstate,
          CompanyAverageOrderValueKey,
          objectType
        );
        dateSet.push(CompanyAverageOrderValue);
        break;
      case "CompanyAverageOrderFrequency":
        const CompanyAverageOrderFrequencyKey = keyMapping("CompanyAverageOrderFrequency");
        const CompanyAverageOrderFrequency = createSingalObjectDataset(
          currentItem,
          formstate,
          CompanyAverageOrderFrequencyKey,
          objectType
        );
        dateSet.push(CompanyAverageOrderFrequency);
        break;
      case "CompanyAverageRevenuePerUser":
        const CompanyAverageRevenuePerUserKey = keyMapping("CompanyAverageRevenuePerUser");
        const CompanyAverageRevenuePerUser = createSingalObjectDataset(
          currentItem,
          formstate,
          CompanyAverageRevenuePerUserKey,
          objectType
        );
        dateSet.push(CompanyAverageRevenuePerUser);
        break;
      default:
        return;
    }
  });
  return dateSet;
};
