import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { getFavourite, getFavouriteCompany, addRemoveFavourite, removeFavouriteCompany } from "../../../services";
import { colorChartMapping } from "../../../../../constants";
import { isEmpty } from "lodash";

const useFavourites = () => {
  const [favouriteItems, setFavouriteItems] = useState([]);
  const [favouriteCompanyList, setFavouriteCompanyList] = useState([]);
  const [companyColorMapping, setCompanyColorMapping] = useState([]);
  const [selecedCompanies, setselecedCompanies] = useState([]);
  const [emptyFavouritesErrorMessage, setEmptyFavouritesErrorMessage] = useState('');
  const [emptyCompanyErrorMessage, setEmptyCompanyErrorMessage] = useState('');

  useEffect(() => {
    favouritesList();
    favouritesCompanyList();
  }, []);

  const favouritesList = async () => {
    try {
      const getFavouriteItems = await getFavourite();
      const { data: responseData = [] } = getFavouriteItems;
      const { body = [] } = responseData;
      setFavouriteItems(body);
      if(isEmpty(body)){
        setEmptyFavouritesErrorMessage("No filters marked as favourites");
      }
    } catch (error) {
      toast.success("something wrong");
    }
  };

  const favouritesCompanyList = async () => {
    try {
      const getFavouriteItems = await getFavouriteCompany();
      const { data: responseData = [] } = getFavouriteItems;
      const { body = [] } = responseData;
      setFavouriteCompanyList(body);
      if(isEmpty(body)){
        setEmptyCompanyErrorMessage("No companies marked as favourites")
      }
    } catch (error) {
      toast.success("something wrong");
    }
  };

  const favouritesSearchDeleta = (id) => async () => {
    if (!id) {
      return;
    }
    try {
      const getFavouriteItems = await addRemoveFavourite({}, id);
      const { data: responseData = [] } = getFavouriteItems;
      const { message = "" } = responseData;
      toast.success(message);
      favouritesList();
    } catch (error) {
      toast.success("something wrong");
    }
  };

  const handleRemoveCompany = (id) => async () => {
    if (!id) {
      return;
    }
    try {
      const getFavouriteItems = await removeFavouriteCompany(id);
      const { data: responseData = [] } = getFavouriteItems;
      const { message = "" } = responseData;
      toast.success(message);
      favouritesCompanyList();
    } catch (error) {
      toast.success("something wrong");
    }
  };

  const updateCompaniesList = (name) => {
    const isAlreadyInsert = selecedCompanies.some((item) => item === name);
    let updateCompanies = [];
    let updateMappingCompanies = [];
    if(selecedCompanies.length >= 5 && !isAlreadyInsert){
      toast.error("Only 5 companies/apps can be selected at a time. Please remove an app to add another.");
 return 
    }
    if (isAlreadyInsert) {
      updateCompanies = selecedCompanies.filter((item) => item !== name) || {};
      updateMappingCompanies = companyColorMapping.filter((item) => item.name !== name) || {};
    } else {
      updateCompanies = [...selecedCompanies, name];
      updateMappingCompanies = updateCompanies.map((colorItem, index) => {
        const { name: colorItemname } = colorItem;
        return {
          name: colorItemname,
          color: colorChartMapping[index],
        };
      });
    }
    setCompanyColorMapping(updateMappingCompanies);
    setselecedCompanies(updateCompanies);
  };

  return {
    favouriteItems,
    favouriteCompanyList,
    favouritesSearchDeleta,
    handleRemoveCompany,
    updateCompaniesList,
    selecedCompanies,
    companyColorMapping,emptyFavouritesErrorMessage,emptyCompanyErrorMessage
  };
};

export default useFavourites;
