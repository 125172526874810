import { useState, useEffect } from "react";
import { isEmpty, max } from "lodash";
import toast from "react-hot-toast";
import { getGrowthRecommendation } from "../services";

import { renderServerData } from "../helper/insightsPlusHelper";
import { colorChartMapping, PROBABILITY_RATE, SECTOR } from "../../..//constants";
import { MODEL, FIND, GROWTH_RATE, RECOMMENDATION } from "../../../constants";

const dateError = "Date range is required";
const probabilityRateError = "Probability rate is required";
const recommendationError = "Recommendation is required";
const metricError = "Metrics is required";

const useInsightsPlus = ({ filterTab, setEmptyInsightGraph, setInsightTableData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [companyColorMapping, setCompanyColorMapping] = useState([]);
  const [getSectorList, setSectorList] = useState([]);
  
  const [formState, setFormState] = useState({
    stateDate: "",
    endDate: "",
    growthRate: "",
    recommendation: "",
    metric: "",
    dateValue: "",
    probabilityRate: "0.1",
    monthlyGrowthRateMin: "0",
    monthlyGrowthRateMax: "1",
    rate: "0.1",
    companies: [],
    growth_rate: "",
    sector: "",
  });

  const [errorStateInsitePlus, setErrorStateInsitePlus] = useState({
    date: "",
    probabilityRate: "",
    monthlyGrowthRateMin: "",
    monthlyGrowthRateMax: "",
    rate: "",
    sector: "",
    recommendation: "",
    metric: "",
    growth_rate: "",
  });

  useEffect(() => {
    setSectorList();
  }, []);

  useEffect(() => {
    const { sector = "" } = formState;
    if (sector) {
      const getItem = !isEmpty(getSectorList) && getSectorList[sector];
      setCompanyList(getItem);
    }
  }, [formState]);

  const emptryState = () => {
    setErrorStateInsitePlus({
      date: "",
      probabilityRate: "",
      monthlyGrowthRateMin: "",
      monthlyGrowthRateMax: "",
      rate: "",
      recommendation: "",
      metric: "",
    });
  };

  useEffect(() => {
    setEmptyInsightGraph();
  }, []);

  const updateFormInsightsPlus = (value = "", name = "") => {
    emptryState();
    if (name === RECOMMENDATION) {
      setSectorList([]);
    }
  if(name ===PROBABILITY_RATE)
    { if(value <0 ){
      return false;
    } else if(value >1 ){
      return false;
    }
    }

    if (name === RECOMMENDATION ) {
      setCompanyList([]);
      setCompanyColorMapping([]);
      setFormState({ ...formState, [name]: value, sector: "", companies: [] });
    } else if (name === SECTOR) {
      setCompanyList([]);
      setCompanyColorMapping([]);
      setFormState({ ...formState, [name]: value, companies: [] });
    }
     else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const updateCompaniesList = (companyItem={}) => {
    const {company:name='',package:packageName=''} = companyItem;
    const { companies = [] } = formState;
    const isAlreadyInsert = companies.some((item) => item.package === packageName);
    let updateCompanies = [];
    let updateMappingCompanies = [];
    
    if(companies.length >= 5 && !isAlreadyInsert){
      toast.error("Only 5 companies/apps can be selected at a time. Please remove an app to add another.");
      return 
    }

    if (isAlreadyInsert) {
      updateCompanies = companies.filter((item) => item.package !== packageName) || {};
      updateMappingCompanies = companyColorMapping.filter((item) => item.name !== packageName) || {};
    } else {
      updateCompanies = [...companies, companyItem];

      const newItem = {
        name:packageName,
        company:name
      };
      const updateMapping = [...companyColorMapping, newItem];
      updateMappingCompanies = updateMapping.map((colorItem, index) => {
        const { name: colorItemname,company:cName='' } = colorItem;
        return {
          company:cName,
          name: colorItemname,
          color: colorChartMapping[index],
        };
      });
    }
    setCompanyColorMapping(updateMappingCompanies);
    const updateItem = { ...formState, companies: updateCompanies };
    setFormState(updateItem);
    
  };

  const isValidForm = () => {
    const {
      stateDate = "",
      endDate = "",
      probabilityRate = "",
      monthlyGrowthRateMin = "",
      monthlyGrowthRateMax = "",
      recommendation,
      metric,
      growth_rate,
    } = formState;
    let updateState = {};
    if (!stateDate && !endDate) {
      updateState.date = dateError;
    }

    if (filterTab === MODEL && !probabilityRate) {
      updateState.probabilityRate = probabilityRateError;
    }

    if ((filterTab === MODEL && !monthlyGrowthRateMin && !monthlyGrowthRateMax)) {
      updateState.monthlyGrowthRateMin = "Min monthly growth rate is required";
      updateState.monthlyGrowthRateMax = "Max monthly growth rate is required";
    }
    
    
    if ((filterTab === MODEL && (monthlyGrowthRateMin > monthlyGrowthRateMax) && monthlyGrowthRateMax !== "")) {
      updateState.monthlyGrowthRateMin = "Min value should be less than max";
    }

    if (filterTab === MODEL && probabilityRate > 1) {
      updateState.probabilityRate = "probability rate (0 to 1)";
    }

 
    if (filterTab === FIND && formState.recommendation === GROWTH_RATE && !growth_rate) {
      updateState.growth_rate = "Growth rate is required";
    }

    if (filterTab === FIND && !recommendation) {
      updateState.recommendation = recommendationError;
    }

    if (filterTab === FIND && formState.recommendation === GROWTH_RATE && !metric) {
      updateState.metric = metricError;
    }

    setErrorStateInsitePlus(updateState);
    return !isEmpty(updateState);
  };

  const handlerSubmit = async (type) => {
    if (isValidForm()) {
      return false;
    }
    setIsLoading(true);
    setEmptyInsightGraph();

    const serverData = renderServerData(formState, filterTab, type);
    const { serverData: data = {}, apiUrl = "" } = serverData;

    try {
      const getAll = await getGrowthRecommendation(data, apiUrl);
      const { data: responseData = {} } = getAll;

      const { data: response = [], success = true, message = "" } = responseData;

      if (success) {
        if(isEmpty(response)){
          toast.error("No record found ");
        }
        setCompanyList([]);
        setCompanyColorMapping([]);
        setFormState({...formState, sector: "", companies: [] });
        setSectorList(response);
        setInsightTableData(response);
        return;
      }
      toast.error(message);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    insightsPlusFormState: formState,
    setInsightsPlusState: setFormState,
    updateFormInsightsPlus,
    isLoading,
    handlerSubmit,
    errorStateInsitePlus,
    emptryState,
    updateCompaniesList,
    companyList,
    setCompanyList,
    companyColorMapping,
    getSectorList,
    setSectorList,
  };
};

export default useInsightsPlus;
