import {
  SET_SEARCH_INSIGHT_FILTERS,
  SET_INSIGHT_GRAPH,
  SET_EMPTY_INSIGHT_GRAPH,
  SET_FILTER_TAB,
  SET_MONTHLY_ACTIVE_USERS,
  SET_DAILY_ACTIVE_USERS,
  SET_AVERAGE_REVENUE_PER_USER,
  SET_AVERAGE_ORDER_FREQUENCY,
  SET_AVERAGE_ORDER_VALUE,
  SET_REACH_GRAPH,
  SET_UNINSTALL_RATE,
  SET_TOTLE_SESSION_GRAPH,
  SET_AVERAGE_SESSION_GRAPH,
  SET_SESSION_OPEN_RATE,
  SET_WEEKLI_ACTIVE_USERS,
  SET_INSIGHT_TABLE_DATA,
} from "../action/insight";

const defaultState = {
  searchInsightFilters: {},
  insightGtaph: [],

  recommendation: {},
  filterTab: "",

  reachGraph: {},
  sessionOpenRate: {},
  averageSessionTime: {},
  weeklyActiveUser: {},
  totalSessiontime: {},
  growthRate: {},
  uninstall: {},
  averageOrderValueGraph: {},
  averageOrderFrequencyGraph: {},
  averageRevenuePerUserGraph: {},
  dailyActiveUserGraph: {},
  monthlyActiveUserGraph: {},
  sessionGraph: {},

  insightTableData: [],
};

const setSearchInsightFilters = (state, action) => ({
  ...state,
  searchInsightFilters: action.data,
});

const setInsightGraph = (state, action) => {
  const { insightGtaph = [] } = state;
  const { data = [] } = action;
  return {
    ...state,
    insightGtaph: [...insightGtaph, ...data],
  };
};

const setInsightTableData = (state, action) => {
  const { data = [] } = action;

  return {
    ...state,
    insightTableData: data,
  };
};

const setEmptyInsightGraph = (state, action) => ({
  ...state,
  insightGtaph: [],
});

const setFilterTab = (state, action) => ({
  ...state,
  filterTab: action.name,
});

const setReachGraph = (state, action) => ({
  ...state,
  reachGraph: action.data,
});

const setMonthlyActiveUserGraph = (state, action) => ({
  ...state,
  monthlyActiveUserGraph: action.data,
});

const setDailyActiveUserGraph = (state, action) => ({
  ...state,
  dailyActiveUserGraph: action.data,
});

const setAverageRevenuePerUserGraph = (state, action) => ({
  ...state,
  averageRevenuePerUserGraph: action.data,
});

const setAverageOrderFrequencyGraph = (state, action) => ({
  ...state,
  averageOrderFrequencyGraph: action.data,
});

const setAverageOrderValueGraph = (state, action) => ({
  ...state,
  averageOrderValueGraph: action.data,
});

const setUninstall = (state, action) => ({
  ...state,
  uninstall: action.data,
});

const setTotalSessiontimeGraph = (state, action) => ({
  ...state,
  totalSessiontime: action.data,
});

const setSessionOpenRateGraph = (state, action) => ({
  ...state,
  sessionOpenRate: action.data,
});

const setAverageSessionTimeGraph = (state, action) => ({
  ...state,
  averageSessionTime: action.data,
});

const setWeeklyActiveUserGraph = (state, action) => ({
  ...state,
  weeklyActiveUser: action.data,
});

const insightReducer = function (state = defaultState, action) {
  switch (action.type) {
    case SET_SEARCH_INSIGHT_FILTERS:
      return setSearchInsightFilters(state, action);
    case SET_EMPTY_INSIGHT_GRAPH:
      return setEmptyInsightGraph(state, action);
    case SET_INSIGHT_GRAPH:
      return setInsightGraph(state, action);
    case SET_FILTER_TAB:
      return setFilterTab(state, action);
    case SET_REACH_GRAPH:
      return setReachGraph(state, action);
    case SET_TOTLE_SESSION_GRAPH:
      return setTotalSessiontimeGraph(state, action);
    case SET_MONTHLY_ACTIVE_USERS:
      return setMonthlyActiveUserGraph(state, action);
    case SET_DAILY_ACTIVE_USERS:
      return setDailyActiveUserGraph(state, action);
    case SET_AVERAGE_REVENUE_PER_USER:
      return setAverageRevenuePerUserGraph(state, action);
    case SET_AVERAGE_ORDER_FREQUENCY:
      return setAverageOrderFrequencyGraph(state, action);
    case SET_AVERAGE_ORDER_VALUE:
      return setAverageOrderValueGraph(state, action);
    case SET_UNINSTALL_RATE:
      return setUninstall(state, action);
    case SET_AVERAGE_SESSION_GRAPH:
      return setAverageSessionTimeGraph(state, action);
    case SET_SESSION_OPEN_RATE:
      return setSessionOpenRateGraph(state, action);
    case SET_WEEKLI_ACTIVE_USERS:
      return setWeeklyActiveUserGraph(state, action);
    case SET_INSIGHT_TABLE_DATA:
      return setInsightTableData(state, action);

    default:
      return state;
  }
};

export default insightReducer;
