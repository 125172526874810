import React from "react";
import { Line } from "react-chartjs-2";

const Chart = (props) => {
  const { chartData = "" } = props;

  const { labels = [], datasets = [], maxDate } = chartData;
  const data = {
    labels,
    datasets,
  };

  const options = {
    cutoutPercentage: 50,

    legend: {
      display: true,
      position: "bottom",
      maxHeight: 10,
      maxWidth: 10,
      labels: {
        fontSize: 10,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div className="lineChart">
      <Line height={250} data={data} options={options} />
    </div>
  );
};

export default Chart;
