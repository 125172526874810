import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";

import Insight from "../../../../icon/Insight";
import Mobile from "../../../../icon/Mobile";
import Star from "../../../../icon/Star";
import Close from "../../../../icon/Close";
import Download from "../../../../icon/Download";
import Save from "../../../../icon/Save";
import Share from "../../../../icon/Share";
import { get, isEmpty } from "lodash";
import { Button, Popup } from "semantic-ui-react";
import { addRemoveFavourite } from "../../../insights/services";
import AddFavouritesModal from "../../modalPopUp/AddFavouritesModal";

const FiltersSummery = (props) => {
  const { searchInsightFilters = {}, handleRemoveFilterAndResetDate , setIsLoadding, uploadPDFFile } = props;
  const [showdropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [savepdfPopup, setSavepdfPopup] = useState(false);
  const [isLoaddingFavourite, setIsLoaddingFavourite] = useState(false);

  const { stateDate = "", endDate = "", sector = [], age, tier, gender } = searchInsightFilters;
  const ShowStateDate = moment(stateDate).format("ll");
  const ShowEndDate = moment(endDate).format("ll");

  const handleRemoveFilter = (filterName = "") => {
    handleRemoveFilterAndResetDate(filterName);
  };

  const hideandShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const hideandSavePDFPopup = () => {
    setSavepdfPopup(!savepdfPopup);
  };

  const handleFavourites = async (reportName = "") => {
    setIsLoaddingFavourite(true);
    const body = {};
    body.name = reportName;
    body.filters = searchInsightFilters;

    try {
      const addFavourite = await addRemoveFavourite(body);
      const { data: responseData = [] } = addFavourite;
      const { message = "" } = responseData;
      toast.success(message);
    } catch (error) {
      toast.success("something wrong");
    }
    setIsLoaddingFavourite(false);
    setShowPopup(false);
  };

  const exportPdf = () => {
    const { companies } = searchInsightFilters;
    setIsLoadding(true);
    htmlToImage.toPng(document.getElementById("capture"), { quality: 0.95 }).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = "my-image-name.jpeg";
      const pdf = new jsPDF("p", "mm", "a1", true);
      pdf.addImage(dataUrl, "PNG", 0, 0);
      const name = `${get(companies, "0", "")}_${Math.random().toFixed(2)}.pdf`;
      pdf.setDisplayMode("original");
      pdf.save(name);
      setIsLoadding(false);
    });
  };

  const savePdf = async (name = "") => {
    setIsLoaddingFavourite(true);
    setIsLoadding(true);
    const fileName = await htmlToImage
      .toPng(document.getElementById("capture"), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        const pdf = new jsPDF("p", "mm", "a1", true);
        pdf.addImage(dataUrl, "PNG", 0, 0);
        pdf.setDisplayMode("original");
        return pdf.output("blob");
      });

    await uploadPDFFile(fileName, name);
    setSavepdfPopup(false);
    setIsLoaddingFavourite(false);
    setIsLoadding(false);
  };

  const renderAgeFilter = (filterData = {}) => {
    const { age = [] } = filterData;
    return age.map((ageGroup, index) => {
      return (
        <StyledAgeFilter key={`${index}_age`} className="flex">
          <div className="mrRight10">{`${ageGroup} YRS`}</div>
        </StyledAgeFilter>
      );
    });
  };

  const renderSecter = (filterData = {}) => {
    let { sector = [] } = filterData;
    if (typeof sector === "string") {
      sector = [`${sector}`];
    }
    return sector.map((sectorGroup, index) => {
      return (
        <StyledAgeFilter key={`${index}_age`} className="flex">
          <div className="mrRight10">{`${sectorGroup}`}</div>
        </StyledAgeFilter>
      );
    });
  };

  const renderTier = (filterData = {}) => {
    const { tier = [] } = filterData;
    return tier.map((tierGroup, index) => {
      return (
        <StyledAgeFilter key={`${index}_age`} className="flex">
          <div className="mrRight10">{`${tierGroup}`}</div>
        </StyledAgeFilter>
      );
    });
  };

  const renderGenderFilter = (filterData = {}) => {
    const { gender = [] } = filterData;
    return gender.map((genderGroup, index) => {
      return (
        <StyledAgeFilter key={`${index}_gender`} className="flex">
          <div className="mrRight10">{genderGroup}</div>
        </StyledAgeFilter>
      );
    });
  };

  const renderCompaniesilter = (filterData = {}) => {
    const { companies = [] } = filterData;
    if(isEmpty(companies)){
      return null;
    }
    return (
      <div className="flex">
        <IconDiv>
          <Mobile size={18} />
        </IconDiv>
        <div>
          {companies.map((companyItem, index) => {
            const {company=''} =companyItem;
            return (
              <StyledCompanyFilter key={`${index}_companry`}>
                <div className="flex">
                  <div className="mrRight10">{company}</div>
                  <StyledClose onClick={() => handleRemoveFilter(companyItem)}>
                    <Close size={10} />
                  </StyledClose>
                </div>
              </StyledCompanyFilter>
            );
          })}
        </div>
      </div>
    );
  };

  const renderAddDropDownTrigger = () => {
    return (
      <span className="dropdownTrigger">
        <Button content="ACTIONS" icon="angle down" labelPosition="right" />
      </span>
    );
  };

  const renderPopupContent = () => {
    return (
      <div className="popcontent">
        <ul>
          <li onClick={hideandSavePDFPopup}>
            <Save size={14} /> Save
          </li>
          <li onClick={exportPdf}>
            <Download size={16} /> Download PDF
          </li>
          <li onClick={hideandShowPopup}>
            <Star size={16} /> Add Favourites
          </li>
          {/* <li>
            <Share size={12} /> Share
          </li> */}
        </ul>
      </div>
    );
  };

  const onclickDropdown = () => {
    setShowDropdown(!showdropdown);
  };

  const renderPopup = () => {
    return (
      <Popup
        hoverable
        open={showdropdown}
        onClose={onclickDropdown}
        on="click"
        position="bottom right"
        onOpen={onclickDropdown}
        content={renderPopupContent()}
        trigger={renderAddDropDownTrigger()}
      />
    );
  };

  return (
    <div className="showfilterBox flex">
      <div className=" w70 ">
        <div className="filterTitleInfo">
          {!isEmpty(sector) && (
            <React.Fragment>
              Showing insights for <b>"{typeof sector === "string" ? sector : get(sector, "0", "")}"</b> apps
            </React.Fragment>
          )}
          {isEmpty(sector) && <React.Fragment>Showing Insights for selected companies/apps</React.Fragment>}
        </div>
        {(!isEmpty(age) || !isEmpty(sector) || !isEmpty(tier) || !isEmpty(gender)) && (
          <div className="flex">
            <IconDiv>
              <Insight size={18} />
            </IconDiv>
            {renderAgeFilter(searchInsightFilters)}
            {renderGenderFilter(searchInsightFilters)}
            {renderSecter(searchInsightFilters)}
            {renderTier(searchInsightFilters)}
          </div>
        )}
        {renderCompaniesilter(searchInsightFilters)}
      </div>
      <div className="flex flexEnd w30">
        <div className="showDateTitel">
          {ShowStateDate} - {ShowEndDate}
          <br />
          {renderPopup()}
        </div>
      </div>
      {showPopup && (
        <AddFavouritesModal
          isLoaddingFavourite={isLoaddingFavourite}
          Title="Save filter as"
          close={showPopup}
          closePopup={hideandShowPopup}
          save={handleFavourites}
          buttonText="Add Filter"
        />
      )}

      {savepdfPopup && (
        <AddFavouritesModal
          isLoaddingFavourite={isLoaddingFavourite}
          Title="Save pdf as"
          close={savepdfPopup}
          closePopup={hideandSavePDFPopup}
          save={savePdf}
          buttonText="Save pdf"
        />
      )}
    </div>
  );
};

const StyledAgeFilter = styled.div`
  padding: 2px 2px 2px 8px;
  font-size: 12px;
  width: fit-content;
  color: #718486;
  border-radius: 4px;
  border: 1px solid grey;
  margin: 0px 10px 20px 0px;
  display: flex;

  text-transform: uppercase;
`;

const StyledCompanyFilter = styled.div`
  padding: 2px 2px 2px 8px;
  font-size: 12px;
  width: fit-content;
  color: #718486;
  border-radius: 4px;
  margin: 0px 10px 10px 0px;
  background: #efefef;
  display: inline-block;
  text-transform: uppercase;
`;

const IconDiv = styled.div`
  margin-top: 6px;
  margin-right: 10px;
`;

const StyledClose = styled.div`
  font-size: 12px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`;

export default FiltersSummery;
