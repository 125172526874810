import React, { useCallback } from 'react'
import toast from "react-hot-toast";
import {
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Dimmer, Loader, Segment } from "semantic-ui-react";

import LeftArrow from "../../../../icon/LeftArrow";

import './table.css'
import { SEND_EMAIL } from '../../../../constants/router';
import axios from '../../../../interceptors';
import { DebouncedInput, OverLayer, Filter, IndeterminateCheckbox } from './other';
import Pagination from './pagination';


/**
 * Insight Table Component
 */
export default function InsightPlusTablePage(props) {
  const { data, isLoading } = props;
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnFilters, setColumnFilters] = React.useState([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  
  const isShowEmptyState = data.length === 0;

  const columns = React.useMemo(
    () => [
          {
            id: 'select',
            header: ({ table }) => (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            ),
            cell: ({ row }) => (
              <div className="px-1">
                <IndeterminateCheckbox
                  {...{
                    checked: row.getIsSelected() && row.getValue('developer_mail'),
                    disabled: !row.getCanSelect() || !row.getValue('developer_mail'),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                />
              </div>
            ),
          },
          {
            accessorKey: 'sector',
            header: 'Sector ',
          },
          {
            accessorKey: 'company_name',
            header: 'App Name ',
          },
          {
            accessorKey: 'reach',
            header: 'Reach ',
          },
          {
            accessorKey: 'monthly_active_users',
            header: 'MAU ',
          },
          {
            accessorKey: 'daily_active_users',
            header: 'DAU ',
          },
          {
            accessorKey: 'total_session_time',
            header: 'Session Time ',
          },
          {
            accessorKey: 'avg_session_time',
            header: 'Avg Session Time ',
          },
          {
            accessorKey: 'opens',
            header: 'Open Rate ',
          },
          {
            accessorKey: 'uninstall_rate',
            header: 'Uninstall Rate ',
          },
          {
            accessorKey: 'monthly_growth_rate',
            header: 'MoM Growthss ',
          },
          {
            accessorKey: 'installs',
            header: 'Installs ',
          },
          {
            accessorKey: 'developer_mail',
            header: 'Developer Mail ',
          },
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      rowSelection,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
  })

  /**
   * callback to send emails
   * using selected rows
   * and api call
   */
  const sendEmails  = useCallback(async () => {
    const selectedRows = 
      table
          .getPrePaginationRowModel()
          .rows
          .filter(row => row.getIsSelected() && row.getValue('developer_mail'))
          .map(row => ({
              appName: row.getValue('company_name'),
              email: row.getValue('developer_mail'),
              installs: row.getValue('installs'),
          }));
    
    if (selectedRows.length === 0) {
      toast.error("Please select at least one row to send email.");
      return
    }

    try {
      await axios.post(SEND_EMAIL, selectedRows);
      toast.success('Emails sent successfully');
    } catch (error) {
      toast.error('Failed to send emails');
    }
  }, [table]);

  const onGlobalSearchHandler = useCallback((value) => {
    setGlobalFilter(String(value))
  }, [])


  if (isShowEmptyState && !isLoading) {
    return (
      <div className="emptyTitle">
        <LeftArrow /> Search Parameters to show Insights
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="overLayer">
        <OverLayer>
          <Segment>
            <Dimmer active inverted>
              <Loader size="small colorfff">Please wait</Loader>
            </Dimmer>
          </Segment>
        </OverLayer>
      </div>
    );
  }

  return (
    <div>
      <div>
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={onGlobalSearchHandler}
          className="p-2 font-lg border rounded"
          placeholder="Search all columns..."
        />
      </div>
      <div className='overflow-auto'>
      <table>
        {/* table header */}
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        {/* table body */}
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      </div>

      {/* start pagination */}
      <Pagination table={table} />

      {/* send email button */}
      <button
        type="button"
        className='ui button submitButton rounded mt-5'
        onClick={sendEmails}
      >
        Send Email
      </button>
    </div>
  )
}


