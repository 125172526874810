// lib
import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { getCompanySearch } from "../../insights/services";
import debounce from "lodash/debounce";

const AutoSuggestWrapper = (props) => {
  const {
    currentValue,
    onSelectSuggestion,
    autoSuggestClasses = "",
    placeholder = "KEYWORD, APP NAME OR COMPANY",
  } = props;

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const onChange = (event, value = {}) => {
    const { newValue = "" } = value;
    setValue(newValue);
  };

  const selectSuggestion = (event, { suggestion, suggestionName, method }) => {
    onSelectSuggestion(suggestion);
    setValue("");
  };

  const suggestionsFetchRequested = async ({value:q}) => {
    try {
      const response = await getCompanySearch(q);
      const { data: responseData = {} } = response;
      const { data = [], success = false } = responseData;

      if (success) {
        setSuggestions(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "KEYWORD, APP NAME OR COMPANY",
    value,
    onChange: onChange,
  };
  const renderSuggestion = (suggestion) => {
    return <span>{suggestion.company}</span>;
  };

  return (
    <div className={autoSuggestClasses}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={suggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) => suggestion.company}
        onSuggestionSelected={selectSuggestion}
        renderSuggestion={renderSuggestion}
        focusInputOnSuggestionClick={true}
        inputProps={inputProps}
      />
    </div>
  );
};

AutoSuggestWrapper.propTypes = {
  currentValue: PropTypes.string,
  itemNodeId: PropTypes.string,
  suggestionsType: PropTypes.string,
  onClickEvent: PropTypes.func,
  onSelectSuggestion: PropTypes.func,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  searchTypeInternal: PropTypes.bool,
};
AutoSuggestWrapper.defaultProps = {
  currentValue: "",
  suggestionsType: "",
  itemNodeId: "",
  onClickEvent: noop,
  onSelectSuggestion: noop,
  placeholder: "",
  data: [],
  searchTypeInternal: false,
};

export default AutoSuggestWrapper;
