import React, { useState, useEffect } from "react";
import get from "lodash/get";
import toast from "react-hot-toast";
import { Redirect } from "react-router-dom";

import { postLoginUser } from "../services";
import { isEmpty } from "lodash";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const useLogin = (setUserInfo) => {
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    grantType: "password",
  });
  const [isloading,setIsloading] = useState(false);
  const [error, setError] = useState(false);

  const updateFormDate = (name, itemValue) => {
    if (name === "email") {
      const isValid = validateEmail(itemValue);
      if (!isValid) {
        setError(true);
      } else {
        setError(false);
      }
    }
    const updateForm = { ...formState, [name]: itemValue };
    setFormState(updateForm);
  };

  const onSubmit = async () => {
    setIsloading(true)
    try {
      if (error) {
        setError(true);
        return;
      }
      const postLogin = await postLoginUser(formState);
      const { data: responseData = [] } = postLogin;
      const { success = false, body = {}, message = "" } = responseData;
      if (!isEmpty(body)) {
        setUserInfo(body);
        return <Redirect to="/insights" />;
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsloading(false)
  };
  return {
    formState,
    updateFormDate,
    error,
    onSubmit,isloading
  };
};

export default useLogin;
