import Header from '../reports/component/Header/container'

const Database = () => {
  return (
    <div>
    <Header pageName = " Database" />

    <div className="outerContainer">
        <div className="container">
          <div className="pd20">Coming Soon</div>
          </div></div>
    </div>
  );
};

export default Database;
