import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Header from "./index";
import {setUserInfo} from '../../../../action/bootstrapReducer'

const mapStateToProps = ({ bootstrapReducer }) => {
  const {user={}} =bootstrapReducer;
  const {access_token=''} = user;
  const isLogin = !!access_token;
  return {isLogin,user};
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
  setUserInfo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
