import React from "react";
import PropTypes from "prop-types";

const Download = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 11.31 18.27"
    >
  <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill={fill} d="M.68,18.27h10v-2H.68Z"/><path  fill={fill} d="M9.9,8,6.68,11.24,6.66,0h-2l0,11.23L1.41,8,0,9.43l5.66,5.66,5.65-5.66Z"/></g></g>
    </svg>
  );
};

Download.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Download.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Download);
