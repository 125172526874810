import React from "react";
import { isEmpty } from "lodash";
import styled from "styled-components";
import useFavourites from "../hook/useFavourites";
import FavouritesCompany from "../../../../common/companyList/FavouritesCompany";
import { Button } from "semantic-ui-react";
import Close from "../../../../../icon/Close";
import { INSIGHT, SEARCH ,colorChartMapping} from "../../../../../constants";

import { dateTimeHelper } from "../../../../../helper";
const LeftPanalFavourites = (props) => {
  const { updateForm, handlerHeaderTab, preFillTab, setPreFillTab, setCompanyColorMapping } = props;
  const {
    favouriteCompanyList = [],
    handleRemoveCompany,
    updateCompaniesList,
    selecedCompanies,emptyCompanyErrorMessage=''
  } = useFavourites();

  const companyColorMapping =
  !isEmpty(selecedCompanies) &&
  selecedCompanies.map((item, index) => {
    return {
      name: item,
      color: colorChartMapping[index],
    };
  });

  const sendToinsights = () => {
    setPreFillTab(SEARCH);
    const getDate = dateTimeHelper(6);
    const { stateDate, endDay } = getDate;
    const newForm = {
      dateValue: 6,
      stateDate: stateDate,
      endDate: endDay,
      companies: selecedCompanies,
    };
    updateForm(newForm);
    handlerHeaderTab(INSIGHT)();
    setCompanyColorMapping(companyColorMapping);
  };

  const renderFilter = (name) => {
    return (
      <StyledAgeFilter key={name} className="flex">
        <div className="mrRight10">{name}</div>
        <StyledClose onClick={() => updateCompaniesList(name)}>
          {" "}
          <Close size={10} />
        </StyledClose>
      </StyledAgeFilter>
    );
  };

  const renderFilterItem = () => {
    return selecedCompanies.map((item) => {
      return renderFilter(item);
    });
  };

  const isDisabled = true;
  return (
    <div className="favouritesList">
      {isEmpty(favouriteCompanyList) && <div className="favourites pl20 marginLeft30" >{emptyCompanyErrorMessage}</div>}
      {!isEmpty(favouriteCompanyList) && (
        <FavouritesCompany
          handleRemoveCompany={handleRemoveCompany}
          companies={favouriteCompanyList}
          onClick={updateCompaniesList}
          selecedCompanies={selecedCompanies}
        />
      )}

      {!isEmpty(selecedCompanies) && (
        <React.Fragment>
          <div className="favourites pl20">
            <div className="favouritesTitle">Selected apps</div>
            {renderFilterItem()}
          </div>
          <div className="plr ">
            <Button
              className={isDisabled ? "submitButton" : "runReports"}
              disabled={isEmpty(selecedCompanies)}
              onClick={sendToinsights}
            >
              SEND TO INSIGHTS
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const StyledClose = styled.div`
  font-size: 12px;
  cursor: pointer;
  padding-right: 10px;
`;

const StyledAgeFilter = styled.div`
  padding: 2px 2px 2px 8px;
  font-size: 12px;
  width: fit-content;
  color: #718486;
  border-radius: 4px;
  margin: 0px 10px 10px 0px;
  background: #efefef;
  display: inline-block;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export default LeftPanalFavourites;
