import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";


const ChevronRight = (props) => {
  const { size, rotate } = props;

  const StyledIcon = styled.span`
    transform: scaleX(${rotate});
  `;

  return (
      <StyledIcon>
        <span>
          <svg
            height={size}
            width={size}
            fill="currentColor"
            viewBox="0 0 16 16"
          >
        
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
          </svg>
        </span>
      </StyledIcon>
  );
};

ChevronRight.propTypes = {
  size: PropTypes.number,
  rotate: PropTypes.number,
};

ChevronRight.defaultProps = {
  size: 16,
  rotate: 1,
};

export default React.memo(ChevronRight);
