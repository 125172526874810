import React from "react";
import PropTypes from "prop-types";

const Close = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      fill={fill}
      viewBox="0 0 10.48 10.48"
    >
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path   fill={fill} d="M10.19.29a1,1,0,0,0-1.41,0L5.24,3.83,1.71.29A1,1,0,0,0,.29,1.71L3.83,5.24.29,8.78a1,1,0,0,0,0,1.41,1,1,0,0,0,1.42,0L5.24,6.66l3.54,3.53a1,1,0,0,0,1.41-1.41L6.66,5.24l3.53-3.53A1,1,0,0,0,10.19.29Z"/></g></g>
    </svg>
  );
};

Close.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Close.defaultProps = {
  size: 12,
  fill: "#999",
};

export default React.memo(Close);
