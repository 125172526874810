import React from "react";
import PropTypes from "prop-types";

const Share = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 14.21 14.21"
    >
   <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill={fill} d="M6.21,2h4.54L0,12.79l1.41,1.42L12.21,3.46V8h2V0h-8Z"/></g></g>
    </svg>
  );
};

Share.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Share.defaultProps = {
  size: 12,
  fill: "#999",
};

export default React.memo(Share);
