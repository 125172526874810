import React from "react";
import PropTypes from "prop-types";

const LeftArrow = (props) => {
  const { size, fill } = props;
  return (
    <svg height={size}
    width={size}  fill={fill} viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
  );
};

LeftArrow.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

LeftArrow.defaultProps = {
  size: 20,
  fill: "#999",
};

export default React.memo(LeftArrow);
