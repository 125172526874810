import React, { useState, useEffect } from "react";
import Header from '../reports/component/Header/container'

const Setting = () => {
  return (
      <div>
        <Header pageName = "My Settings" />
        <div className="outerContainer">
        <div className="container">
          <div className="pd20">Coming Soon</div>
          </div></div>
 
    </div>
  );
};

export default Setting;
