import React from "react";
import PropTypes from "prop-types";

const Timer = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      fill={fill}
      viewBox="0 0 451.09 571.83"
    >
   
   <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill={fill} d="M225.55,571.83C101.18,571.83,0,470.65,0,346.28S101.18,120.73,225.55,120.73,451.09,221.91,451.09,346.28,349.91,571.83,225.55,571.83Zm0-398.5c-95.37,0-173,77.58-173,172.95s77.58,173,173,173,173-77.58,173-173S320.91,173.33,225.55,173.33Z"/><rect fill={fill} x="369.61" y="131.7" width="61.84" height="85.2" rx="18.91" transform="translate(240.56 -232.17) rotate(45)"/><rect fill={fill} x="19.65" y="131.7" width="61.84" height="85.2" rx="18.91" transform="translate(209.56 261.78) rotate(135)"/><rect fill={fill} x="199.44" y="81.11" width="52.22" height="49.39"/><rect fill={fill} x="165.08" width="120.92" height="60.46" rx="18.84"/><path fill={fill} d="M225.55,212.74V346.28H92A133.54,133.54,0,1,0,225.55,212.74Z"/></g></g>
    </svg>
  );
};

Timer.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Timer.defaultProps = {
  size: 12,
  fill: "#fdc730",
};

export default React.memo(Timer);
