// Libraries
import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";

import Star from "../../../icon/Star";
import Close from "../../../icon/Close";

import "./style/index.css";

const CompanyList = (props) => {
  const {
    companyName = "",
    companies = [],
    onClick,
    selecedCompanies = [],
    addFavouriteCompanyName,
    showFavouritestab = false,
  } = props;

  const [companiesList, setCompaniesList] = useState(companies);

  useEffect(() => {
    setCompaniesList(companies);
  }, [companies]);

  const onClickSearch = (name = "") => {
    if (name.length > 1) {
      const filterData = companies.filter((item) => item.company.toLowerCase().includes(name.toLowerCase()))||{};
      if(!isEmpty(filterData)){
        setCompaniesList(filterData);
      }
      else{
        setCompaniesList(companies);
      }
    
      return;
    }
    setCompaniesList(companies);
  };

  const renderTitle = () => {
    return (
      <div className="mb15">
        <h4>
          Showing apps{" "}
          {companyName && (
            <React.Fragment>
              for <span>"{companyName}"</span>
            </React.Fragment>
          )}
        </h4>
        <p>(add up to 5 companies - click to select & deselect)</p>
      </div>
    );
  };

  const renderCompanies = () => {
    return (
      <div className="listPanal  overflowX showScrollbar">
        {!isEmpty(companiesList) &&
          companiesList.map((item, index) => {
            const { company = "", isFavourite = false ,package:packageName=""} = item;
            // icon fill color flag
            const isSelectedItem = selecedCompanies.some((item) => item.package === packageName);
            const iconFill = isFavourite ? "#ffc82d" : "#999";
            const addclassname = isSelectedItem ? "listItem active" : "listItem";
            const key = `${packageName}_${index}`;
            return (
              <div key={key} className={addclassname}>
                <div
                  className="name"
                  onClick={() => {
                    onClick(item);
                  }}
                >
                  {company}
                </div>
                {!isFavourite && showFavouritestab && (
                  <span onClick={addFavouriteCompanyName(company)}>
                    <Star size={16} fill={iconFill} />
                  </span>
                )}
                {isFavourite && showFavouritestab && (
                  <span>
                    <Star size={16} fill={iconFill} />
                  </span>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  const renderSelecedCompanies = () => {
    return (
      !isEmpty(selecedCompanies) && (
        <div className="mt20 mb20">
          <div className="smallTitle">Selected companies/ Apps</div>
          <div className="companiesTagList">
            {selecedCompanies.map((item) => {
              const {company='',package:packageName} = item;
              return (
                <div key={packageName} className="tagInner">
                  <div className="companiesTag">
                    <p>{company}</p>
                    <span
                      className="close"
                      onClick={() => {
                        onClick(item);
                      }}
                    >
                      <Close size={10} />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )
    );
  };

  const renderSearchBox = () => {
    // if (companiesList.length < 8) {
    //   return null;
    // }
    return (
      <div className="mT15 w100">
        <Input
          icon="search"
          onChange={(e, data) => onClickSearch(data.value)}
          placeholder="Company search..."
          className="w100"
        />
      </div>
    );
  };

  return (
    <div className="showcompanyList plr">
      {renderTitle()}
      {renderSearchBox()}
      {renderCompanies()}
      {renderSelecedCompanies()}
    </div>
  );
};

export default CompanyList;
