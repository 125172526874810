export const INSIGHT_PLUS = "insightplus";
export const DATABASE = "database";
export const REPORTS = "reports";
export const SETTINGS = "settings";
export const SETTING = "Setting";
export const INSIGHT = "insight";
export const INSIGHT_TABLE = "insight-table";
export const FAVOURITES = "favourites";
export const START_DATE = "stateDate";
export const END_DATE = "endDate";
export const SECTOR = "sector";
export const REGION = "tier";
export const GENDER = "gender";
export const AGE_GROUP = "age";
export const BROWSE = "browse";
export const SEARCH = "search";
export const FIND = "find";
export const MODEL = "model";
export const CUSTOM = "custom";
export const KEY_9 = "9";
export const GROWTH_RATE = "growthRate";
export const RECOMMENDATION = "recommendation";
export const METRIC = "metric";
export const PROBABILITY_RATE = "probabilityRate";
export const MONTHLY_GROWTH_RATE_MIN = "monthlyGrowthRateMin";
export const MONTHLY_GROWTH_RATE_MAX = "monthlyGrowthRateMax";
export const RATE = "rate";
export const START_GROWTH_RATE = "growth_rate";
export const END_GROWTH_RATE = "end_growth_rate";

export const filterTab = [
  { name: "Browse", key: "browse", active: true },
  { name: "Search", key: "search", active: false },
];

export const filterTabInsightsPlus = [
  { name: "Find", key: "find", active: true },
  { name: "Model", key: "model", active: false },
];

export const filterDate = [
  { text: "Last 3 Months", value: 3, key: "3", active: false },
  { text: "Last 6 Months", value: 6, key: "6", active: false },
  { text: "Last 9 Months", value: 9, key: "9", active: false },
  { text: "Last 1 Year", value: 12, key: "12", active: false },
  { text: "Custom*", value: "custom", key: "custom", active: false },
];

// Left Menu
export const leftNav = [
  {
    name: "Insight plus",
    key: "insightplus",
    url: "/insights",
  },
  { name: "Reports", key: "reports", url: "/reports" },
  { name: "Settings", key: "Setting", url: "/Setting" },
  { name: "Database", key: "database", url: "/database" },
];

export const topInsightHeaderMenu = [
  {
    name: "Insight",
    key: INSIGHT,
    active: true,
  },
  { name: "InsightPlus", key: INSIGHT_PLUS, active: false },
  { name: "InsightPlusTable", key: INSIGHT_TABLE, active: false },
  { name: "Favourites", key: FAVOURITES, active: false },
];

export const colorChartMapping = [
  "rgb(47,127,187)",
  "rgb(204,102,255)",
  "rgb(43,64,153)",
  "rgb(51,102,255)",
  "rgb(0,199,185)",
  "rgb(79,79,79)",
  "rgb(225,138,1)",
  "rgb(149,187,47)",
  "rgb(187,70,47)",
];

export const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)";
  }
  throw new Error("Bad Hex");
};

export const filterRegion = [
  {
    text: "Tier 1",
    value: "Tier1",
  },
  {
    text: "Tier 2",
    value: "Tier2",
  },
  {
    text: "Tier 3",
    value: "Tier3",
  },
  {
    text: "Metro",
    value: "Metro",
  },
];

export const filterYears = [
  {
    text: "18-24 Years",
    value: "18-24",
  },
  {
    text: "25-35 Years",
    value: "25-35",
  },
  {
    text: "36-45 Years",
    value: "36-45",
  },
  {
    text: "45+",
    value: "45+",
  },
];

export const filterGender = [
  // {
  //   text: "Other",
  //   value: "other",
  // },
  {
    text: "Male",
    value: "male",
  },
  {
    text: "Female",
    value: "female",
  },
];

export const matric = [
  {
    text: "Reach",
    value: "reach",
  },
  {
    text: "Monthly Active User",
    value: "monthly_active_users",
  },
  {
    text: "Total Session Time",
    value: "total_session_time",
  },
  {
    text: "Average Session Time",
    value: "avg_session_time",
  },
  {
    text: "Open",
    value: "opens",
  },
];

export const GrowthRate = [
  {
    text: "J Curve",
    value: "J Curve",
  },
  {
    text: "Exponential",
    value: "Exponential",
  },
  {
    text: "Linear",
    value: "Linear",
  },
  {
    text: "Growth Rate",
    value: "growthRate",
  },
];
