import { combineReducers } from "redux";

import bootstrapReducer from "./bootstrapReducer";
import insightReducer from "./insightReducer";

const rootReducer = combineReducers({
  bootstrapReducer,
  insightReducer,
});

export default rootReducer;
