import React, { useState } from "react";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";

import { BROWSE, SEARCH, FIND, MODEL } from "../../../../constants/";

// components
import PageTitle from "../pageTitle";
import Charts from "../../../common/chart/index";
import FiltersSummery from "../../../common/filter/filtersSummery/FiltersSummery";

import LeftArrow from "../../../../icon/LeftArrow";

const InsightsDetailPage = (props) => {
  const {
    filterTab: typeSearch = "",
    searchInsightFilters = {},
    reachGraph,
    averageOrderValueGraph,
    averageOrderFrequencyGraph,
    averageRevenuePerUserGraph,
    dailyActiveUserGraph,
    uninstall,
    weeklyActiveUser,
    averageSessionTime,
    sessionOpenRate,
    totalSessiontime,
    monthlyActiveUserGraph,
    handleRemoveFilterAndResetDate,
    isLoadingRunReport,
    uploadPDFFile,
  } = props;
  const [isLoadding, setIsLoadding] = useState(false);
  const renderLodding = () => {
    return (
      <div className="lodding">
        <div className="innerLodding">
          <Segment className="w50">
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
          </Segment>
          <Segment className="w50">
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
          </Segment>
        </div>
        <div className="innerLodding">
          <Segment className="w50">
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
          </Segment>
          <Segment className="w50">
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
          </Segment>
        </div>
        <div className="innerLodding">
          <Segment className="w50">
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
          </Segment>
          <Segment className="w50">
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
          </Segment>
        </div>
      </div>
    );
  };

  const renderItem = (item) => {
    if (isEmpty(item)) {
      return null;
    }
    const { name = "" } = item;
    return (
      <ChartsCart key={name}>
        <PageTitle title={name} />
        <ChartsItem>
          <Charts chartData={item} />
        </ChartsItem>
      </ChartsCart>
    );
  };

  const isShowEmptyState =
    !isEmpty(reachGraph) ||
    !isEmpty(averageOrderValueGraph) ||
    !isEmpty(averageOrderFrequencyGraph) ||
    !isEmpty(averageRevenuePerUserGraph) ||
    !isEmpty(dailyActiveUserGraph) ||
    !isEmpty(uninstall) ||
    !isEmpty(weeklyActiveUser) ||
    !isEmpty(averageSessionTime) ||
    !isEmpty(sessionOpenRate) ||
    !isEmpty(totalSessiontime) ||
    !isEmpty(monthlyActiveUserGraph);

  const emptyState = () => {
    if (!isShowEmptyState) {
      if (typeSearch === BROWSE || typeSearch === FIND || typeSearch === MODEL) {
        return (
          <div className="emptyTitle">
            <LeftArrow /> Search Parameters to show Insights
          </div>
        );
      }
      if (typeSearch === SEARCH) {
        return (
          <div className="emptyTitle">
            <LeftArrow /> Search Companies to show Insights
          </div>
        );
      }
    }
  };

  const renderUninstall = () => {
    return renderItem(uninstall);
  };

  const renderReach = () => {
    return renderItem(reachGraph);
  };
  const renderAverageOrderValueGraph = () => {
    return renderItem(averageOrderValueGraph);
  };
  const renderAverageOrderFrequencyGraph = () => {
    return renderItem(averageOrderFrequencyGraph);
  };
  const renderAverageRevenuePerUserGraph = () => {
    return renderItem(averageRevenuePerUserGraph);
  };
  const renderDailyActiveUserGraph = () => {
    return renderItem(dailyActiveUserGraph);
  };
  const renderWeeklyActiveUser = () => {
    return renderItem(weeklyActiveUser);
  };

  const renderAverageSessionTime = () => {
    return renderItem(averageSessionTime);
  };

  const renderSessionOpenRate = () => {
    return renderItem(sessionOpenRate);
  };

  const renderTotalSessiontime = () => {
    return renderItem(totalSessiontime);
  };

  const renderMonthlyActiveUserGraph = () => {
    return renderItem(monthlyActiveUserGraph);
  };

  return (
    <React.Fragment>
      <div className="pd20 h100" id="capture">
        {!isEmpty(searchInsightFilters) && (
          <FiltersSummery
            setIsLoadding={setIsLoadding}
            uploadPDFFile={uploadPDFFile}
            isLoadding={isLoadding}
            searchInsightFilters={searchInsightFilters}
            handleRemoveFilterAndResetDate={handleRemoveFilterAndResetDate}
          />
        )}
        {emptyState()}
        {isLoadingRunReport && renderLodding()}
        {!isLoadingRunReport && (
          <React.Fragment>
            {renderReach()}
            {renderTotalSessiontime()}
            {renderAverageSessionTime()}
            {renderSessionOpenRate()}
            {renderMonthlyActiveUserGraph()}
            {renderWeeklyActiveUser()}
            {renderDailyActiveUserGraph()}
            {renderUninstall()}
            {renderAverageOrderValueGraph()}
            {renderAverageOrderFrequencyGraph()}
            {renderAverageRevenuePerUserGraph()}
          </React.Fragment>
        )}
      </div>
      {isLoadding && (
        <div className="overLayer">
          <OverLayer>
            <Segment>
              <Dimmer active inverted>
                <Loader size="small colorfff">Please wait</Loader>
              </Dimmer>
            </Segment>
          </OverLayer>
        </div>
      )}
    </React.Fragment>
  );
};

const ChartsItem = styled.div`
  border: 1px solid #f5f6f8;
  padding: 20px;
`;
const ChartsCart = styled.div`
  width: 50%;
  display: inline-block;
  padding: 0 20px;
  margin-bottom: 40px;
`;

const OverLayer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #7f7d7db8;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  segment {
    background: none;
    .ui.segment > .ui.dimmer {
      background: none !important;
    }
    .text {
      color: #fff;
    }
    .ui.inverted.dimmer .ui.loader:after {
      border-color: #ffffff transparent transparent !important;
    }
  }
`;

export default InsightsDetailPage;
