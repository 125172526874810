import { connect } from "react-redux";

import InsightsDetailPage from "./index";

const mapStateToProps = ({ bootstrapReducer, insightReducer = {} }) => {
  const {
    insightGtaph = [],
    filterTab = "",
    searchInsightFilters = {},
    reachGraph,
    uninstall,
    averageOrderValueGraph,
    averageOrderFrequencyGraph,
    averageRevenuePerUserGraph,
    dailyActiveUserGraph,
    weeklyActiveUser,
    averageSessionTime,
    sessionOpenRate,
    totalSessiontime,
    monthlyActiveUserGraph,
  } = insightReducer;
  return {
    insightGtaph,
    filterTab,
    searchInsightFilters,
    reachGraph,
    averageOrderValueGraph,
    averageOrderFrequencyGraph,
    averageRevenuePerUserGraph,
    dailyActiveUserGraph,
    uninstall,
    weeklyActiveUser,
    averageSessionTime,
    sessionOpenRate,
    totalSessiontime,
    monthlyActiveUserGraph,
  };
};

//const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps)(InsightsDetailPage);
