import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import get from "lodash/get";
import {
  getSectorList,
  getCompanyList,
  getReach,
  getSession,
  getMonthlActiveUser,
  getCompanyWeeklyActiveUser,
  getDailyActiveUsers,
  getCompanyAverageRevenuePerUser,
  getCompanyAverageOrderValue,
  getAverageOrderFrequency,
  getUninstallRate,
  addFavouriteCompany,
  pdfFileUpload,
} from "../services";

import { sessionDataSet, createDataset, createSingalObjectDataset, convertFormData } from "../helper";
import { topInsightHeaderMenu, INSIGHT, SECTOR, colorChartMapping } from "../../../constants";
import {
  REACH,
  MONTHLY_ACTIVE_USERS,
  AVERAGE_REVENUE_PER_USER,
  AVERAGE_ORDER_FREQUENCY,
  AVERAGE_ORDER_VALUE,
  UNINSTALL_RATE,
  DAILY_ACTIVE_USERS,
  WEEKLY_ACTIVE_USERS,
} from "../../../constants/filter";
import { isEmpty } from "lodash";
const dateError = "Date range is required";
const companiesError = "Companies is required";

const useInsights = ({
  insightGtaph,
  setInsightGraph,
  setEmptyInsightGraph,
  setSearchInsightFilters,
  searchInsightFilters,
  setUninstall,
  setReachGraph,
  setMonthlyActiveUserGraph,
  setDailyActiveUserGraph,
  setAverageRevenuePerUserGraph,
  setAverageOrderFrequencyGraph,
  setAverageOrderValueGraph,
  setWeeklyActiveUserGraph,
  setAverageSessionTimeGraph,
  setSessionOpenRateGraph,
  setTotalSessiontimeGraph,
}) => {
  const [headerMenu, setHeaderMenu] = useState([]);
  const [headerCurrentItem, setHeaderCurrentItem] = useState("");
  const [sectorList, setSectorList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [isLoadingRunReport, setIsLoadingRunReport] = useState(false);
  const [formStateCallback, setFormStateCallback] = useState(false);
  const [insightsFormUpdate, setInsightsFormUpdate] = useState(false);
  const [preFillTab, setPreFillTab] = useState("");
  const [formState, setFormState] = useState({
    dateValue: "",
    stateDate: "",
    endDate: "",
    sector: [],
    gender: [],
    age: [],
    tier: [],
    companies: [],
  });
  const [companyColorMapping, setCompanyColorMapping] = useState([]);
  const [errorState, setErrorState] = useState({
    date: "",
    companies: "",
  });
const [count, setCount] = useState(0)

  useEffect(() => {
    setHeaderMenu(topInsightHeaderMenu);
    setHeaderCurrentItem(INSIGHT);
    getAllSectorList();
  }, []);

  useEffect(() => {
    if (formStateCallback && !isEmpty(formState.companies)) {
      handlerRunReports();
    }
    else {
      if (isEmpty(formState.companies)) {
        setSearchInsightFilters(formState);
        emptyDetailPage()
      }

    }
  }, [formState]);

  const emptyDetailPage = () => {
    setUninstall({});
    setReachGraph({});
    setMonthlyActiveUserGraph({});
    setDailyActiveUserGraph({});
    setAverageRevenuePerUserGraph({});
    setAverageOrderFrequencyGraph({});
    setAverageOrderValueGraph({});
    setWeeklyActiveUserGraph({});
    setAverageSessionTimeGraph({});
    setSessionOpenRateGraph({});
    setTotalSessiontimeGraph({});
    setSearchInsightFilters({});
  };

  const handlerHeaderTab = (itemName) => () => {
    const updateTab = headerMenu.map((item) => {
      if (item.key === itemName) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setHeaderMenu(updateTab);
    setHeaderCurrentItem(itemName);
    searchInsightFilters();
    setEmptyInsightGraph();
    emptyDetailPage();
  };

  const getAllSectorList = async () => {
    try {
      const getAll = await getSectorList();
      const { data: responseData = [] } = getAll;
      const { success = false, data = {} } = responseData;
      if (success) {
        setSectorList(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllCompanyList = async () => {
    console.log(get(formState.sector, "0", ""))
    setIsLoadingCompany(true);
    try {
      const getAll = await getCompanyList(get(formState.sector, "0", ""));
      const { data: responseData = [] } = getAll;
      const { success = false, data = {} } = responseData;
      if (success) {
        setCompanyList(data);
        setFormState({ ...formState, companies: [] });
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoadingCompany(false);
  };

  const updateFormStateWithKey = (value, name) => {
    const updateItem = { ...formState, [name]: value };
    setFormState(updateItem);
  };

  const henderDropdownValue = (value, name) => {
    console.log(value, name, formState)
    const updateValue = value ? [value] : [];

    if (name === SECTOR) {
      setCompanyList();
    }
    const updateItem = { ...formState, [name]: updateValue };
    setFormState(updateItem);
    setErrorState({ date: "", companies: "" });
  };

  const updateFormState = (value, name) => {
    const getCurrentState = formState[name] || [];
    const isAlreadyInsert = getCurrentState.some((item) => item === value);
    let updateFormStateWithKey = [];
    if (isAlreadyInsert) {
      updateFormStateWithKey = getCurrentState.filter((item) => item !== value) || {};
    } else {
      updateFormStateWithKey = [...getCurrentState, value];
    }
    const updateItem = { ...formState, [name]: updateFormStateWithKey };
    setFormState(updateItem);
  };

  const handlerCompany = () => {
    if (formState.sector) {
      const updateItem = { ...formState, companies: [] };
      setFormState(updateItem);
      getAllCompanyList();
    }
  };

  const updateCompanies = (companyItem = {}) => {
    const { company: name = '', package: packageName = '' } = companyItem;
    const { companies = [] } = formState;
    const isAlreadyInsert = companies.some((item) => item.package === packageName);
    if (companies.length >= 5 && !isAlreadyInsert) {
      toast.error("Only 5 companies/apps can be selected at a time. Please remove an app to add another.");
      return
    }


    let updateCompanies = [];
    let updateMappingCompanies = [];
    if (isAlreadyInsert) {
      updateCompanies = companies.filter((item) => item.package !== packageName) || {};
      updateMappingCompanies = companyColorMapping.filter((item) => item.name !== packageName) || {};
    } else {
      updateCompanies = [...companies, companyItem];

      const newItem = {
        name: packageName,
        company: name
      };
      const updateMapping = [...companyColorMapping, newItem];
      updateMappingCompanies = updateMapping.map((colorItem, index) => {
        const { name: colorItemname, company: cName = '' } = colorItem;
        return {
          company: cName,
          name: colorItemname,
          color: colorChartMapping[index],
        };
      });
    }
    setCompanyColorMapping(updateMappingCompanies);
    const updateItem = { ...formState, companies: updateCompanies };
    setFormState(updateItem);

  };

  const handleRemoveFilterAndResetDate = (item = {}) => {
    updateCompanies(item);
    setFormStateCallback(true);
  };

  const isValidForm = () => {
    const { stateDate = "", endDate = "", companies = [] } = formState;
    let updateState = {};
    if (!stateDate && !endDate) {
      updateState.date = dateError;
    }
    if (isEmpty(companies)) {
      updateState.companies = companiesError;
    }

    setErrorState(updateState);
    return !isEmpty(updateState);
  };

  useEffect(() => {
    if (insightsFormUpdate) {
      handlerRunReports();
    }
  }, [insightsFormUpdate]);

  const handlerRunReportsInsightsPlus = (form = {}, colorMapping = []) => {
    setFormState(form);
    setCompanyColorMapping(colorMapping);
    setInsightsFormUpdate(true);
  };

  const handlerRunReports = async () => {
    const fetchData = { ...formState }
    if (formState.dateValue != 'custom') {
      fetchData.stateDate = new Date("2022-1-1")
      // console.log(formState)
      // console.log(fetchData)
      const pageLoad = convertFormData(fetchData, "reachMonthly");
      const getAll = await getReach(pageLoad);
      const maxDate = getAll?.data?.data?.maxDate
      console.log(maxDate)
      const [year, month, day] = maxDate.split("-")
      const newEndDate = new Date(maxDate)
      newEndDate.setHours(0,0,0,0)
      const m = month - fetchData.dateValue
      const newStartDate = new Date(year, m, day)
      fetchData.stateDate = newStartDate
      fetchData.endDate = newEndDate
      setFormState({...fetchData})
      console.log(formState)
    } else {
      console.log("Hey this is custom", formState)
    }
    setCount(count+1)
    setIsLoadingRunReport(true);
    if (isValidForm()) {
      toast.error("Some filter are missing");
      setIsLoadingRunReport(false);
      return true;
    }
    
  };

  useEffect(async ()=>{
    setSearchInsightFilters(formState);
    await setEmptyInsightGraph();
    await getDataSetReach();
    await getDataSetSession();
    await getMau();
    await getweeklyActiveUser();
    await getDailyActiveUser();
    await getAverageRevenuePerUser();
    await getAverageOrderValue();
    await getCompanyAverageOrderFrequency();
    await getUninstall();
    setIsLoadingRunReport(false);
    setFormStateCallback(false);
    setInsightsFormUpdate(false);
    return;
  },[count])

  const getDataSetReach = async () => {
    const pageLoad = convertFormData(formState, "reachMonthly");
    try {
      const getAll = await getReach(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (!isEmpty(data)) {
          const pageKey = {
            keyMonthName: "reach_monthly__date_label_month",
            keyGetItem: "reach_monthly__reach",
            company: "reach_monthly__company uninstall_rate__company",
            pageTitle: REACH,
            order: 1,
          };
          const items = createSingalObjectDataset(data, formState, pageKey, false, companyColorMapping, maxDate);
          // setInsightGraph(items);
          setReachGraph(get(items, "0", {}));
        } else {
          setReachGraph({});
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    //  setIsLoadingRunReport(false);
  };

  const getDataSetSession = async () => {
    const pageLoad = sessionDataSet(formState);
    try {
      const getAll = await getSession(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (isEmpty(data)) {
          setAverageSessionTimeGraph({});
          setSessionOpenRateGraph({});
          setTotalSessiontimeGraph({});
          return true;
        }
        const items = createDataset(data, formState, "", companyColorMapping, maxDate);
        setAverageSessionTimeGraph(get(items, "1", {}));
        setSessionOpenRateGraph(get(items, "0", {}));
        setTotalSessiontimeGraph(get(items, "2", {}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getMau = async () => {
    const pageLoad = convertFormData(formState, "monthlyActiveUsers");
    try {
      const getAll = await getMonthlActiveUser(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (isEmpty(data)) {
          setMonthlyActiveUserGraph({});
          return true;
        }
        const pageKey = {
          keyMonthName: "monthly_active_users__date_label_month",
          keyGetItem: "monthly_active_users__monthly_active_users",
          pageTitle: MONTHLY_ACTIVE_USERS,
          order: 5,
        };
        const items = createSingalObjectDataset(data, formState, pageKey, false, companyColorMapping, maxDate);
        setMonthlyActiveUserGraph(get(items, "0", {}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getweeklyActiveUser = async () => {
    const pageLoad = convertFormData(formState, "weeklyAvtiveUser");
    try {
      const getAll = await getCompanyWeeklyActiveUser(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (isEmpty(data)) {
          setWeeklyActiveUserGraph({});
          return true;
        }
        const pageKey = {
          keyMonthName: "weekly_active_users__date_label_month",
          keyGetItem: "weekly_active_users__weekly_active_users",
          pageTitle: WEEKLY_ACTIVE_USERS,
          order: 6,
        };
        const items = createSingalObjectDataset(data, formState, pageKey, false, companyColorMapping, maxDate);
        setWeeklyActiveUserGraph(get(items, "0", {}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getDailyActiveUser = async () => {
    const pageLoad = convertFormData(formState, "dailyActiveUser");
    try {
      const getAll = await getDailyActiveUsers(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (isEmpty(data)) {
          setDailyActiveUserGraph({});

          return true;
        }
        const pageKey = {
          keyMonthName: "daily_active_users__date_label_month",
          keyGetItem: "daily_active_users__daily_active_users",
          pageTitle: DAILY_ACTIVE_USERS,
          order: 7,
        };
        const items = createSingalObjectDataset(data, formState, pageKey, false, companyColorMapping, maxDate);
        setDailyActiveUserGraph(get(items, "0", {}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAverageRevenuePerUser = async () => {
    const pageLoad = convertFormData(formState, "averageRevenuePerUser");
    try {
      const getAll = await getCompanyAverageRevenuePerUser(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (isEmpty(data)) {
          setAverageRevenuePerUserGraph({});
          return true;
        }
        const pageKey = {
          keyMonthName: "monthly__date_label_month",
          keyGetItem: "monthly__arpu",
          pageTitle: AVERAGE_REVENUE_PER_USER,
          order: 11,
        };
        const items = createSingalObjectDataset(data, formState, pageKey, false, companyColorMapping, maxDate);
        setAverageRevenuePerUserGraph(get(items, "0", {}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAverageOrderValue = async () => {
    const pageLoad = convertFormData(formState, "averageOrderValue");
    try {
      const getAll = await getCompanyAverageOrderValue(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (isEmpty(data)) {
          setAverageOrderValueGraph({});
          return true;
        }
        const pageKey = {
          keyMonthName: "monthly__date_label_month",
          keyGetItem: "monthly__aov",
          pageTitle: AVERAGE_ORDER_VALUE,
          order: 9,
        };
        const items = createSingalObjectDataset(data, formState, pageKey, false, companyColorMapping, maxDate);
        setAverageOrderValueGraph(get(items, "0", {}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCompanyAverageOrderFrequency = async () => {
    const pageLoad = convertFormData(formState, "averageOrderFrequency");
    try {
      const getAll = await getAverageOrderFrequency(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (isEmpty(data)) {
          setAverageOrderFrequencyGraph({});
          return true;
        }
        const pageKey = {
          keyMonthName: "monthly__date_label_month",
          keyGetItem: "monthly__aof",
          pageTitle: AVERAGE_ORDER_FREQUENCY,
          order: 10,
        };
        const items = createSingalObjectDataset(data, formState, pageKey, false, companyColorMapping, maxDate);
        setAverageOrderFrequencyGraph(get(items, "0", {}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUninstall = async () => {
    const pageLoad = convertFormData(formState, "uninstall");
    try {
      const getAll = await getUninstallRate(pageLoad);
      const { data: responseData = [] } = getAll;
      const { success = false, data: rootData = {} } = responseData;
      if (success) {
        const { data = {}, maxDate = "" } = rootData;
        if (isEmpty(data)) {
          setUninstall({});
          return true;
        }
        const pageKey = {
          keyMonthName: "uninstall_rate__date_label_month",
          keyGetItem: "uninstall_rate__uninstall_rate",
          company: "uninstall_rate__company",
          pageTitle: UNINSTALL_RATE,
          order: 8,
        };
        const items = createSingalObjectDataset(data, formState, pageKey, false, companyColorMapping, maxDate);
        setUninstall(get(items, "0", {}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addFavouriteCompanyName = (name) => async () => {
    try {
      const addFavourite = await addFavouriteCompany(name);
      const { data: responseData = [] } = addFavourite;
      const { message = "" } = responseData;
      toast.success(message);
    } catch (error) {
      toast.error("something wrong");
    }
  };

  const uploadPDFFile = async (file, name) => {
    try {
      const addFavourite = await pdfFileUpload(file, name);
      const { data: responseData = [] } = addFavourite;
      const { message = "", success = false, error = "" } = responseData;
      if (success) {
        toast.success(message);
        return;
      }
      toast.error(error);
    } catch (error) {
      toast.error("something wrong");
    }
  };

  return {
    headerMenu,
    formState,
    updateFormState,
    sectorList,
    handlerCompany,
    companyList,
    updateCompanies,
    setFormState,
    handlerRunReports,
    updateFormStateWithKey,
    henderDropdownValue,
    isLoadingCompany,
    isLoadingRunReport,
    handlerHeaderTab,
    headerCurrentItem,
    errorState,
    setCompanyList,
    addFavouriteCompanyName,
    setCompanyColorMapping,
    emptyDetailPage,
    handleRemoveFilterAndResetDate,
    handlerRunReportsInsightsPlus,
    uploadPDFFile,
    preFillTab,
    setPreFillTab,
  };
};

export default useInsights;
