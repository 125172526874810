import React from "react";
import styled from "styled-components";

const PageTitle = (props) => {
  const { title = "" } = props;

  return <GTitle dangerouslySetInnerHTML={{ __html: title }}></GTitle>;
};

const GTitle = styled.div`
  color: #718486;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
  span {
    text-transform: capitalize;
    font-size: 12px;
  }
`;

export default PageTitle;
