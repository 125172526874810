import React from "react";
import PropTypes from "prop-types";

const Star = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.55 20.55"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={fill}
            d="M20.42,7.66A2.45,2.45,0,0,0,18.05,6H14a.21.21,0,0,1-.15-.1l-1.07-4A2.6,2.6,0,0,0,10.27,0,2.63,2.63,0,0,0,7.75,1.93l-1,4A.2.2,0,0,1,6.55,6H2.49A2.42,2.42,0,0,0,.13,7.66a2.7,2.7,0,0,0,1,3l3,2.32a.28.28,0,0,1,.08.31L3.09,17a2.73,2.73,0,0,0,1,3.06,2.58,2.58,0,0,0,3-.07l3-2.32a.12.12,0,0,1,.15,0l3,2.32a2.56,2.56,0,0,0,3,.07,2.74,2.74,0,0,0,1-3.06L16.3,13.28a.29.29,0,0,1,.08-.31l3-2.32A2.73,2.73,0,0,0,20.42,7.66ZM6.59,14a2.73,2.73,0,0,0-.94-3l-3-2.32a.88.88,0,0,1-.18-.21H6.55A2.67,2.67,0,0,0,9.07,6.56l1-3.95a.17.17,0,0,1,.31,0l1.07,4A2.65,2.65,0,0,0,14,8.49l4.1-.05s0,.14-.17.26l-3,2.32A2.71,2.71,0,0,0,14,14l1.15,3.75a.25.25,0,0,1-.09.31c-.07.05-.09,0-.14,0l-3-2.32a2.55,2.55,0,0,0-3.14,0l-3,2.32s-.07.05-.15,0a.26.26,0,0,1-.09-.31Z"
          />
        </g>
      </g>
    </svg>
  );
};

Star.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Star.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Star);
