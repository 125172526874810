import React from "react";
import { Toaster } from "react-hot-toast";

import { Redirect } from "react-router-dom";

import { Button, Form, Grid, Header, Segment, Input } from "semantic-ui-react";
import Logo from "../../icon/Logo";
import useLogin from "./hook/useLogin";

const LoginForm = (props) => {
  const { setUserInfo, isLogin } = props;

  const { formState, updateFormDate, error: emailError = false, onSubmit,isloading= false } = useLogin(setUserInfo);

  const { password = "", email = "" } = formState;
  const isDisabled = !password || emailError;

  if (isLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center" style={{ display: "flex", alignItems: "center" }}>
          <Logo /> <span style={{ marginLeft: 10 }}> Log-in to your account</span>
        </Header>
        <Form size="large">
          <Segment stacked>
            <div>
              <Input
                onChange={(data, e) => updateFormDate("email", e.value)}
                value={email}
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                error={emailError}
              />
              {emailError && <div className="errorMess">Please enter the valid email id</div>}
            </div>
            <div className="mT15">
              <Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onChange={(data, e) => updateFormDate("password", e.value)}
                value={password}
              />
            </div>
            <div className="mT15">
              <Button color="black" disabled={isDisabled} loading={isloading} fluid size="large" onClick={onSubmit}>
                Login
              </Button>
            </div>
            <Toaster />
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
