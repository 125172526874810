import { SET_USER } from "../action/bootstrapReducer";

const defaultState = {
  isFetchingPage: true,
  user: {
    name: "",
    access_token: "",
    email: "",
  },
};

const bootstrapReducer = function (state = defaultState, action) {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: action.data,
      };
    }

    default: {
      return state;
    }
  }
};

export default bootstrapReducer;
