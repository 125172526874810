import React, { useState } from "react";
import { Button, Modal, Input } from "semantic-ui-react";

const AddFavouritesModal = (props) => {
  const {
    Title = "",
    content: contentText = "",
    closePopup,
    save,
    isLoaddingFavourite = false,
    buttonText = "",
  } = props;
  const [name, setName] = useState("");

  const updateNameclick = (value = "") => {
    setName(value);
  };
  return (
    <Modal size="mini" open onClose={closePopup}>
      <Modal.Header>{Title}</Modal.Header>
      <Modal.Content>
        <Input
          onChange={(data, e) => updateNameclick(e.value)}
          value={name}
          fluid
          label="Name"
          placeholder="facebook Insights"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closePopup}>Close</Button>
        <Button
          loading={isLoaddingFavourite}
          disabled={!name}
          onClick={() => {
            save(name);
          }}
          positive
        >
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddFavouritesModal;
