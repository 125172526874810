import React from "react";
import PropTypes from "prop-types";

const Database = (props) => {
  const { size, fill } = props;
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={fill}
            d="M11,0C5.71,0,0,1.53,0,4V20c0,2.47,5.71,4,11,4s11-1.53,11-4V4C22,1.53,16.29,0,11,0Zm0,2c5.16,0,8.58,1.43,9,2.08V5.92C19.61,6.56,16.18,8,11,8S2.39,6.56,2,5.92V4.08C2.42,3.43,5.84,2,11,2Zm9,10.92c-.39.64-3.82,2.08-9,2.08s-8.61-1.44-9-2.08V8.3A21.82,21.82,0,0,0,11,10a21.82,21.82,0,0,0,9-1.7ZM11,22c-5.16,0-8.58-1.43-9-2.08V15.3A21.82,21.82,0,0,0,11,17a21.82,21.82,0,0,0,9-1.7v4.62C19.58,20.57,16.16,22,11,22Z"
          />
        </g>
      </g>
    </svg>
  );
};

Database.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

Database.defaultProps = {
  size: 30,
  fill: "#999",
};

export default React.memo(Database);
